import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LoopIcon from '@mui/icons-material/Loop';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import FolderIcon from '@mui/icons-material/Folder';
import { ATTACHMENT_STATUS } from '../../utils/EnumDefinitions';
import WarningIcon from '@mui/icons-material/Warning';

const TSGallery = ({ dataId, subdataId, images, handleRemovePic, handleAddPic, openCamera, handleUploadPic, handleDownloadPic }) => {
  const [imgs, setImgs] = useState({});
  const [selectedImgKey, setSelectedImgKey] = useState(undefined);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setImgs(images);
    if (images != undefined && selectedImgKey == undefined && Object.keys(images).length > 0) {
      setSelectedImgKey(Object.keys(images)[0]);
    }
  }, [JSON.stringify(images)]);

  const handleRemoveImage = (key) => {
    const keys = Object.keys(imgs);
    const index = keys.indexOf(key);
    if (keys.length == 1) {
      setSelectedImgKey(undefined);
    } else {
      if (index +1 != keys.length) {
        setSelectedImgKey(keys[index+1]);
      } else {
        setSelectedImgKey(keys[index-1]);
      }
    }
    delete imgs[key];
    handleRemovePic(dataId, subdataId, key);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const blob = new Blob([file], { type: file.type });
      handleAddPic(blob);
    }
  };

  const getUploadStatusButton = (dataId, subdataId, imgObj, key) => {
    if (imgObj.status == ATTACHMENT_STATUS.DONE) {
      return (
        <IconButton
          style={{
            position: 'absolute',
            top: 10,
            right: 50,
            color: 'green',
            backgroundColor: 'white',
            border: '1px solid green'
          }}
          size="small"
        >
          <DownloadDoneIcon fontSize="small" />
        </IconButton>
      );
    } else if (imgObj.status == ATTACHMENT_STATUS.UPLOADING || imgObj.status == ATTACHMENT_STATUS.DOWNLOADING) {
      return (
        <IconButton
          style={{
            position: 'absolute',
            top: 10,
            right: 50,
            color: 'blue',
            backgroundColor: 'white',
            border: '1px solid blue'
          }}
          size="small"
        >
          <LoopIcon fontSize="small" />
        </IconButton>
      );
    } else if (imgObj.status == ATTACHMENT_STATUS.NOT_DOWNLOADED) {
      return (
        <IconButton
          style={{
            position: 'absolute',
            top: 10,
            right: 50,
            color: 'blue',
            backgroundColor: 'white',
            border: '1px solid blue'
          }}
          onClick={() => handleDownloadPic(dataId, subdataId, key, true)}
          size="small"
        >
          <DownloadIcon fontSize="small" />
        </IconButton>
      );
    } else {
      return (
        <IconButton
          style={{
            position: 'absolute',
            top: 10,
            right: 50,
            color: 'blue',
            backgroundColor: 'white',
            border: '1px solid blue'
          }}
          onClick={() => handleUploadPic(dataId, subdataId, imgObj.data, true)}
          size="small"
        >
          <CloudUploadIcon fontSize="small" />
        </IconButton>
      );
    }
  };

  return (
    <Grid container sx={{ my: 3 }} alignContent='flex-start'>
      <Grid item xs={12} justifyContent='center' display='flex' sx={{ mb: 2 }}>
        {selectedImgKey != undefined ? (
          <div style={{ position: 'relative', display: 'inline-block' }}>
            {imgs[selectedImgKey].data != undefined ? (
              <Box justifyContent='center' alignItems='center' display='flex'
                sx= {{ border: '1px dotted grey', backgroundColor: 'black', width: { xs: '350px', sm: '450px' }, height: '250px' }}>
                <img src={URL.createObjectURL(imgs[selectedImgKey].data)} alt="Selected" style={{ maxWidth: '100%', maxHeight: '100%' }} />
              </Box>
            ): (
              <Box width="450px" height="250px" justifyContent='center' alignItems='center' display='flex'
                style= {{ border: '1px solid black', backgroundColor: 'lightGrey' }}>
                <WarningIcon color='warning'/>
              </Box>
            )}
            <IconButton
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
                color: 'red',
                backgroundColor: 'white',
                border: '1px solid red'
              }}
              onClick={() => handleRemoveImage(selectedImgKey)}
              size="small"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
            {getUploadStatusButton(dataId, subdataId, imgs[selectedImgKey], selectedImgKey)}
          </div>
        ) : <Typography variant='h5' align='center' sx={{ mb: 2 }}>No Images to View. Take a photo or upload from your library</Typography>}
      </Grid>
      <Grid item xs={12} justifyContent={'center'} display={'flex'}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: Object.keys(imgs).length > 0 ? { xs: 'repeat(3, 1fr)', sm: 'repeat(4, 1fr)' } : 'repeat(2, 1fr)',
            gap: 1
          }}
        >
          {imgs != undefined && Object.keys(imgs).length > 0 ? (
            Object.keys(imgs).map((key) => (
              <Box
                key={key}
                sx={{
                  width: '100px',
                  height: '75px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {imgs[key].data != undefined ? (
                  <img
                    src={URL.createObjectURL(imgs[key].data)}
                    onClick={() => setSelectedImgKey(key)}
                    style={{ width: '100%', height: '100%', cursor: 'pointer', border: selectedImgKey == key ? '2px solid blue' : '0px' }}
                  />
                ) : (
                  <>
                    <Box width="100px" height="75px" justifyContent='center' alignItems='center' display='flex' onClick={() => setSelectedImgKey(key)}
                      style= {{ border: selectedImgKey == key ? '2px solid blue' : '0px', backgroundColor: 'lightGrey' }}>
                      <WarningIcon color='warning'/>
                    </Box>
                  </>
                )}
              </Box>
            ))
          ) : ''}
          <Box
            sx={{
              width: '100px',
              height: '75px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px dotted #000',
              cursor: 'pointer'
            }}
            onClick={() => openCamera(dataId, subdataId)}
          >
            <AddAPhotoIcon/>
          </Box>
          <Box
            sx={{
              width: '100px',
              height: '75px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px dotted #000',
              cursor: 'pointer'
            }}
            onClick={() => fileInputRef.current.click()}
          >
            <FolderIcon/>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }} // Hidden input element
              onChange={handleFileChange}
              accept="image/*"
              multiple // Allow multiple file selection
            />
          </Box>
        </Box>
        {/* */}
      </Grid>
    </Grid>
  );
};

TSGallery.propTypes = {
  dataId: PropTypes.number,
  subdataId: PropTypes.number,
  images: PropTypes.object,
  handleRemovePic: PropTypes.func,
  handleAddPic: PropTypes.func,
  openCamera: PropTypes.func,
  handleUploadPic: PropTypes.func,
  handleDownloadPic: PropTypes.func
};

export default TSGallery;
