import React, { useRef, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const TSCamera = ({ isOpen, handleImageCapture }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    const getCameraStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          setShowVideo(true);
        }
      } catch (error) {
        console.error('Error accessing camera:', error);
      }
    };

    getCameraStream();

    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [isOpen]);

  const handleCapture = async () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0);

    const imageDataUrl = canvas.toDataURL('image/png');
    const response = await fetch(imageDataUrl);
    const blob = await response.blob();
    handleImageCapture(blob);
  };

  return (
    <Box textAlign="center" mt={4}>
      <div style={{ position: 'relative', textAlign: 'center' }}>
        <video ref={videoRef} autoPlay style={{ width: '100%', maxWidth: '500px' }} />
        {showVideo ? (
          <IconButton
            onClick={handleCapture}
            style={{
              position: 'absolute',
              bottom: '20px',
              left: '50%',
              transform: 'translateX(-50%)',
              backgroundColor: '#FFF',
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',
              zIndex: 1
            }}
          >
            <CameraAltIcon />
          </IconButton>
        ) : ''}
        <canvas ref={canvasRef} style={{ display: 'none' }} />
      </div>
    </Box>
  );
};

TSCamera.propTypes = {
  isOpen: PropTypes.bool,
  handleImageCapture: PropTypes.func
};

export default TSCamera;
