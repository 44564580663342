import { AppBar, Backdrop, Badge, Button, Card, Chip, CircularProgress, Dialog, Divider, FormControl, Grid,
  IconButton, InputAdornment, InputLabel, MenuItem, Select, Switch, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { validateNumber, validateStringForNull } from '../../../../utils/FieldValidations';
import Services from '../../../../utils/Services';
import APIData from '../../../../utils/APIData';
import { blue } from '@mui/material/colors';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { CookieUtils, Utils } from '../../../../utils/UtilFunctions';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { cloneDeep } from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomAutocomplete from '../../../../common/components/CustomAutocomplete';
import { ATTACHMENT_STATUS, MATERIAL_TYPE } from '../../../../utils/EnumDefinitions';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CloseIcon from '@mui/icons-material/Close';
import ViewPlanDetails from '../planning/ViewPlanDetails';
import Slide from '@mui/material/Slide';
import moment from 'moment';
import TSCamera from '../../../../common/components/TSCamera.js';
import TSGallery from '../../../../common/components/TSGallery.js';
import CachedIcon from '@mui/icons-material/Cached';
import WarningIcon from '@mui/icons-material/Warning';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const OrdersBooking = () => {
  const [isLoading, setIsLoading] = useState(false);
  const purchaserId = CookieUtils.getUserId();
  const [planList, setPlanList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [markList, setMarkList] = useState([]);
  const [isViewPlanDialogOpen, setIsViewPlanDialogOpen] = useState(false);
  const [isUpdateRequest, setIsUpdateRequest] = useState(false);
  const [isCameraDialogOpen, setIsCameraDialogOpen] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [cameraParams, setCameraParams] = useState({});
  const [planProductQtyDetails, setPlanProductQtyDetails] = useState({});
  const [orderDetails, setOrderDetails] = useState({
    plan_id: '',
    order_id: '',
    ac_name: '',
    ac_id: '',
    order_date: moment().format( 'DD-MM-YYYY'),
    order_data: [],
    refresh_id: 0
  });
  const [originalValue, setOriginalValue] = useState(orderDetails);

  const readyMadeSubDataDefaultObject = {
    id: '',
    model_id: '',
    model_name: '',
    brand_id: '',
    brand_name: '',
    size_id: '',
    buy_price: '',
    bought_quantity: '',
    quantity: '',
    attachments: {}
  };
  const readyMadeDefaultObject = {
    id: '',
    cate_id: '',
    cate_name: '',
    prod_id: '',
    prod_name: '',
    item_id: '',
    material_type: '',
    mark_name: '',
    is_expanded: true,
    sub_data: []
  };

  const silkSubDataDefaultObject = {
    id: '',
    vrty_id: '',
    vrty_name: '',
    buy_price: '',
    sell_price: '',
    description: '',
    quantity: '',
    attachments: {},
    qty_calc_sp: false
  };

  const silkDefaultObject = {
    id: '',
    prod_id: '',
    prod_name: '',
    is_expanded: true,
    item_id: '',
    material_type: '',
    mark_name: '',
    sub_data: []
  };

  const polyesterSubDataDefaultObject = {
    id: '',
    vrty_id: '',
    vrty_name: '',
    buy_price: '',
    sell_price: '',
    description: '',
    quantity: '',
    qty_per_set: '6',
    no_of_sets: '',
    attachments: {}
  };

  const polyesterDefaultObject = {
    id: '',
    prod_id: '',
    prod_name: '',
    is_expanded: true,
    item_id: '',
    material_type: '',
    mark_name: '',
    sub_data: []
  };
  const APIToken = {
    GET_PLAN_LIST: 'COB01',
    GET_BOOKING_ORDER_LIST: 'COB02',
    GET_BOOKING_ORDER: 'COB03',
    GET_ORDER_QTY: 'COB04',
    GET_PRODUCT_ID: 'COB05',
    CREATE_BOOKING_ORDER: 'COB06',
    UPDATE_BOOKING_ORDER: 'CON07',
    CALCULATE_SELL_PRICE: 'COB08',
    GET_MARK_LIST: 'COBO9',
    UPLOAD_ATTACHMENTS: 'COB10',
    DOWNLOAD_ATTACHMENT: 'COB11',
    DOWNLOAD_ATTACHMENTS: 'COB12'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if (validateStringForNull(purchaserId)) {
      raiseGetMarkListRequest();
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getMarkListForOrders && apiToken == APIToken.GET_MARK_LIST ) {
      setMarkList(response.data.mark_list);
      raiseGetPlanListRequest();
    } else if (apiData == APIData.getPlanList && apiToken == APIToken.GET_PLAN_LIST ) {
      setPlanList(response.data);
    } else if (apiData == APIData.getBookingOrderList && apiToken == APIToken.GET_BOOKING_ORDER_LIST) {
      if (response.data != undefined) {
        const updatedOrderList = [
          ...response.data,
          { order_id: '-- NEW --', order_date: moment().format( 'DD-MM-YYYY') }
        ];
        setOrderList(updatedOrderList);
        const filteredOrder = updatedOrderList.find((obj) => obj.order_id == '-- NEW --');
        orderDetails.order_id = filteredOrder.order_id;
        orderDetails.order_date = filteredOrder.order_date;
        orderDetails.plan_id = callbackValues.planId;
        orderDetails.ac_name = '',
        orderDetails.ac_id = '',
        orderDetails.order_data = [];
        notifyOrderDetailsChange();
      } else {
        showSnackBar('error', response.message ?? 'unable to retrieve order list');
      }
    } else if (apiData == APIData.getBookingOrder && apiToken == APIToken.GET_BOOKING_ORDER) {
      if (response.data != undefined) {
        setIsUpdateRequest(true);
        populateOrderData(response.data);
      } else {
        showSnackBar('error', response.message ?? 'unable to retrieve order details');
      }
    } else if (apiData == APIData.createBookingOrder && apiToken == APIToken.CREATE_BOOKING_ORDER) {
      showSnackBar('success', response.message ?? 'Order Created successfully');
      setPlanProductQtyDetails({});
      if (validateStringForNull(callbackValues.plan_id)) {
        raiseGetOrderListRequest(orderDetails.plan_id);
      }
    } else if (apiData == APIData.updateBookingOrder && apiToken == APIToken.UPDATE_BOOKING_ORDER) {
      showSnackBar('success', response.message ?? 'Order Updated Successfully');
      setPlanProductQtyDetails({});
      if (validateStringForNull(callbackValues.orderId)) {
        raiseGetOrderRequest(callbackValues.orderId);
      }
    } else if (apiData == APIData.getProductId && apiToken == APIToken.GET_PRODUCT_ID) {
      setProductName(callbackValues.id, response.data);
    } else if (apiData == APIData.calculateSellPrice && apiToken == APIToken.CALCULATE_SELL_PRICE) {
      callbackValues.subData.sell_price = response.data.sell_price;
      notifyOrderDetailsChange();
    } else if (apiData == APIData.uploadAttachments && apiToken == APIToken.UPLOAD_ATTACHMENTS) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      const attachmentId = response.data.attachments[callbackValues.imageKey];
      if (attachmentId == undefined) {
        subData.attachments[callbackValues.imageKey].attachment_id = undefined;
        subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NEW;
        if (callbackValues.showSnackBar == true) {
          showSnackBar('error', 'Image upload failed. Try again. ' + (response.message != undefined ? `[Error: + ${response.message}] ` : ''));
        }
      } else {
        subData.attachments[callbackValues.imageKey].attachment_id = attachmentId;
        subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.DONE;
      }
      notifyOrderDetailsChange();
    } else if (apiData == APIData.downloadAttachment && apiToken == APIToken.DOWNLOAD_ATTACHMENT) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      const attachment = response.data.attachment;
      const attachmentId = response.data.attachment_id;
      let showSB = false;
      if (attachmentId == subData.attachments[callbackValues.imageKey].attachment_id) {
        try {
          subData.attachments[callbackValues.imageKey].data = Utils.base64ToFile(attachment, callbackValues.imageKey);
          subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.DONE;
        } catch (err) {
          showSB = true;
          subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
        }
      } else {
        showSB = true;
        subData.attachments[callbackValues.imageKey].data = undefined;
        subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
      }
      if (callbackValues.showSnackBar == true && showSB == true) {
        showSnackBar('error', 'Image download failed. Try again. ' + (response.message != undefined ? `[Error: ${response.message}] ` : ''));
      }
      notifyOrderDetailsChange();
    } else if (apiData == APIData.downloadAttachments && apiToken == APIToken.DOWNLOAD_ATTACHMENTS) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      const attachments = response.data;
      let showSB = false;
      callbackValues.imageKeys.map((key) => {
        const aId = subData.attachments[key].attachment_id;
        const arr = attachments.find((obj) => obj.attachment_id == aId);
        if (arr != undefined && arr != null) {
          const attachment = arr.attachment;
          try {
            subData.attachments[key].data = Utils.base64ToFile(attachment, key);
            subData.attachments[key].status = ATTACHMENT_STATUS.DONE;
          } catch (err) {
            showSB = true;
            subData.attachments[key].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
          }
        }
      });
      if (callbackValues.showSnackBar == true && showSB == true) {
        showSnackBar('error', 'Few images failed to download. Try again. ' + (response.message != undefined ? `[Error: ${response.message}]` : ''));
      }
      notifyOrderDetailsChange();
    } else if (apiData == APIData.getOrderQuantity && apiToken == APIToken.GET_ORDER_QTY) {
      if (validateStringForNull(callbackValues.prodId) && validateStringForNull(callbackValues.itemId)) {
        const key = generateQtyObjKey(callbackValues.itemId, callbackValues.prodId);
        const value = response.data;
        if (planProductQtyDetails[key] == undefined) {
          setPlanProductQtyDetails((prevState) => ({
            ...prevState,
            [key]: value
          }));
        }
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getMarkListForOrders && apiToken == APIToken.GET_MARK_LIST ) {
      defaultMsg = 'Failed to retrieve mark list';
    } else if (apiData == APIData.getPlanList && apiToken == APIToken.GET_PLAN_LIST) {
      defaultMsg = 'Failed to retrieve Plan list';
    } else if (apiData == APIData.getBookingOrderList && apiToken == APIToken.GET_BOOKING_ORDER_LIST) {
      defaultMsg = 'Failed to retrieve Order list';
    } else if (apiData == APIData.getBookingOrder && apiToken == APIToken.GET_BOOKING_ORDER) {
      defaultMsg = 'Failed to retrieve Order Details';
    } else if (apiData == APIData.createBookingOrder && apiToken == APIToken.CREATE_BOOKING_ORDER) {
      defaultMsg = 'Failed to Create order';
    } else if (apiData == APIData.updateBookingOrder && apiToken == APIToken.UPDATE_BOOKING_ORDER) {
      defaultMsg = 'Failed to Update order';
    } else if (apiData == APIData.getProductId && apiToken == APIToken.GET_PRODUCT_ID) {
      defaultMsg = 'Failed to retrieve product details';
    } else if (apiData == APIData.calculateSellPrice && apiToken == APIToken.CALCULATE_SELL_PRICE) {
      defaultMsg = 'Failed to Calulate sell price';
    } else if (apiData == APIData.uploadAttachments && apiToken == APIToken.UPLOAD_ATTACHMENTS) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      subData.attachments[callbackValues.imageKey].attachment_id = undefined;
      subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NEW;
      if (callbackValues.showSnackBar == true) {
        showSnackBar('error', 'Image upload failed. Try again. ' + (err.message != undefined ? `[Error: ${err.message}]` : ''));
      }
      notifyOrderDetailsChange();
      return;
    } else if (apiData == APIData.downloadAttachment && apiToken == APIToken.DOWNLOAD_ATTACHMENT) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      subData.attachments[callbackValues.imageKey].data = undefined;
      subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
      if (callbackValues.showSnackBar == true ) {
        showSnackBar('error', 'Image download failed. Try again. ' + (err.message != undefined ? `[Error: ${err.message}] ` : ''));
      }
      notifyOrderDetailsChange();
      return;
    } else if (apiData == APIData.downloadAttachments && apiToken == APIToken.DOWNLOAD_ATTACHMENTS) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      callbackValues.imageKeys.map((key) => {
        subData.attachments[key].data = undefined;
        subData.attachments[key].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
      });
      if (callbackValues.showSnackBar == true) {
        showSnackBar('error', 'Few images failed to download. Try again. ' + (err.message != undefined ? `[Error: ${err.message}] ` : ''));
      }
      notifyOrderDetailsChange();
      return;
    } else if (apiData == APIData.getOrderQuantity && apiToken == APIToken.GET_ORDER_QTY) {
      defaultMsg = 'Failed to retrieve plan/bought qty for the given product';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetMarkListRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getMarkListForOrders },
      APIToken.GET_MARK_LIST, processSuccess, processError);
  };

  const raiseGetPlanListRequest = () => {
    setIsLoading(true);
    const params = 'purchaser_id=' + purchaserId;
    Services.sendBackendRequest({ apiData: APIData.getPlanList, params: params },
      APIToken.GET_PLAN_LIST, processSuccess, processError);
  };

  const populateOrderAttachments = (attachmentIds, dataId, subdataId) => {
    const attachments = {};
    if (attachmentIds == undefined) return attachments;
    attachmentIds.map((id, i) => {
      const key = dataId.toString() + subdataId.toString() + moment().valueOf().toString() + i + '.png';
      const obj = {
        attachment_id: id,
        status: ATTACHMENT_STATUS.NOT_DOWNLOADED
      };
      attachments[key]= obj;
    });
    return attachments;
  };

  const populateOrderData = (data) => {
    const obj = {};
    const qtyDetailsMap = {};
    data.order_data.forEach((order) => {
      const materialType = MATERIAL_TYPE[order.material_type];
      const key = generateQtyObjKey(order.item_id, order.prod_id);
      if (qtyDetailsMap[key] == undefined) {
        qtyDetailsMap[key] = [];
      }
      const productQtyDetails = {
        bought_quantity: order.bought_quantity,
        planned_quantity: order.planned_quantity,
        min_price: order.min_price,
        max_price: order.max_price,
        serial: order.plan_serial,
        model_id: validateStringForNull(order.model_id) ? order.model_id : undefined,
        brand_id: validateStringForNull(order.brand_id) ? order.brand_id : undefined
      };
      qtyDetailsMap[key].push(productQtyDetails);
      if (materialType == 3) {
        const key = `${order.item_id}_${order.cate_id}_${order.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            cate_name: order.cate_name,
            cate_id: order.cate_id,
            prod_id: order.prod_id,
            prod_name: order.prod_name,
            item_id: order.item_id,
            material_type: materialType,
            mark_name: order.mark_name,
            is_expanded: true,
            sub_data: []
          };
        }
        const readymadeObj = {
          id: obj[key].sub_data.length + 1,
          model_name: order.model_name,
          brand_name: order.brand_name,
          model_id: order.model_id,
          brand_id: order.brand_id,
          buy_price: order.buy_price,
          size_id: validateStringForNull(order.size_id) ? order.size_id : '',
          quantity: order.quantity,
          description: validateStringForNull(order.description) ? order.description : '',
          bought_quantity: order.bought_quantity,
          planned_quantity: order.planned_quantity,
          plan_serial: validateStringForNull(order.plan_serial) ? order.plan_serial : -1,
          serial: order.serial
        };
        readymadeObj.attachments = populateOrderAttachments(order.attachment_id, obj[key].id, readymadeObj.id);
        obj[key].sub_data.push(readymadeObj);
      } else if (materialType == 2) {
        const key = `${order.item_id}_${order.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            prod_id: order.prod_id,
            prod_name: order.prod_name,
            is_expanded: true,
            item_id: order.item_id,
            material_type: materialType,
            mark_name: order.mark_name,
            sub_data: []
          };
        }
        const silkObj = {
          id: obj[key].sub_data.length + 1,
          buy_price: order.buy_price,
          sell_price: validateStringForNull(order.sell_price) ? order.sell_price : '',
          quantity: order.quantity,
          vrty_id: validateStringForNull(order.vrty_id) ? order.vrty_id : '',
          description: validateStringForNull(order.description) ? order.description : '',
          bought_quantity: order.bought_quantity,
          planned_quantity: order.planned_quantity,
          qty_calc_sp: validateStringForNull(order.qty_calc_sp) ? order.qty_calc_sp : false,
          plan_serial: validateStringForNull(order.plan_serial) ? order.plan_serial : -1,
          serial: order.serial
        };
        silkObj.attachments = populateOrderAttachments(order.attachment_id, obj[key].id, silkObj.id);
        obj[key].sub_data.push(silkObj);
      } else {
        const key = `${order.item_id}_${order.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            prod_id: order.prod_id,
            prod_name: order.prod_name,
            is_expanded: true,
            item_id: order.item_id,
            material_type: materialType,
            mark_name: order.mark_name,
            sub_data: []
          };
        }
        const polyesterObj = {
          id: obj[key].sub_data.length + 1,
          buy_price: order.buy_price,
          quantity: order.quantity,
          description: validateStringForNull(order.description) ? order.description : '',
          vrty_id: validateStringForNull(order.vrty_id) ? order.vrty_id : '',
          bought_quantity: order.bought_quantity,
          planned_quantity: order.planned_quantity,
          no_of_sets: parseInt(order.quantity / 6),
          qty_per_set: 6,
          plan_serial: validateStringForNull(order.plan_serial) ? order.plan_serial : -1,
          serial: order.serial
        };
        polyesterObj.attachments = populateOrderAttachments(order.attachment_id, obj[key].id, polyesterObj.id);
        obj[key].sub_data.push(polyesterObj);
      }
    });
    setPlanProductQtyDetails(qtyDetailsMap);
    const filteredData = Object.values(obj);
    const filteredOrderObj = {
      plan_id: data.plan_id,
      order_id: data.order_id,
      order_date: data.order_date,
      ac_id: validateStringForNull(data.ac_id) ? data.ac_id: '',
      ac_name: validateStringForNull(data.ac_name) ? data.ac_name: '',
      order_data: filteredData,
      refresh_id: 0
    };
    setOrderDetails(filteredOrderObj);
    setOriginalValue(cloneDeep(filteredOrderObj));
  };

  const raiseGetOrderListRequest = (planId) => {
    setIsLoading(true);
    const params = 'plan_id=' + planId;
    Services.sendBackendRequest({ apiData: APIData.getBookingOrderList, params: params },
      APIToken.GET_BOOKING_ORDER_LIST, processSuccess, processError, { planId: planId });
  };

  const raiseGetOrderRequest = (orderId) => {
    setIsLoading(true);
    const params = 'order_id=' + orderId;
    Services.sendBackendRequest({ apiData: APIData.getBookingOrder, params: params },
      APIToken.GET_BOOKING_ORDER, processSuccess, processError);
  };

  const raiseGetOrderQuantity = (prodId, itemId) => {
    setIsLoading(true);
    const params = 'plan_id=' + orderDetails.plan_id + '&prod_id=' + prodId + '&item_id=' + itemId;
    Services.sendBackendRequest({ apiData: APIData.getOrderQuantity, params: params },
      APIToken.GET_ORDER_QTY, processSuccess, processError, { prodId: prodId, itemId: itemId });
  };

  const raiseCreateOrUpdateOrderRequest = (finalParams) => {
    let apiData = undefined;
    let apiToken = undefined;
    if (isUpdateRequest) {
      apiData = APIData.updateBookingOrder;
      apiToken = APIToken.UPDATE_BOOKING_ORDER;
    } else {
      apiData = APIData.createBookingOrder;
      apiToken = APIToken.CREATE_BOOKING_ORDER;
    }
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: apiData, params: finalParams },
      apiToken, processSuccess, processError, { plan_id: finalParams.plan_id, orderId: finalParams.order_id });
  };

  const raiseGetProductDetailsRequest = (id, productId) => {
    setIsLoading(true);
    const params = 'prod_id=' + productId;
    Services.sendBackendRequest({ apiData: APIData.getProductId, params: params },
      APIToken.GET_PRODUCT_ID, processSuccess, processError, { id: id });
  };

  const handlePlanChange = (event) => {
    const { name, value } = event.target;
    setOrderDetails({ ...orderDetails,
      [name]: value,
      order_id: '',
      order_data: [],
      ac_id: '',
      ac_name: ''
    });
    raiseGetOrderListRequest(value);
    setPlanProductQtyDetails({});
  };

  const handleViewPlanClick = () => {
    if (validateStringForNull(orderDetails.plan_id)) {
      setIsViewPlanDialogOpen(true);
    }
  };

  const handleProductDetailsSearch = (id, prodId, itemId) => {
    if (validateStringForNull(prodId)) {
      raiseGetProductDetailsRequest(id, prodId);
    }
    if (validateStringForNull(prodId) && validateStringForNull(itemId)) {
      const key = generateQtyObjKey(itemId, prodId);
      if (planProductQtyDetails[key] == undefined) {
        raiseGetOrderQuantity(prodId, itemId);
      }
    }
  };

  const handleOrderChange = (event) => {
    const { name, value } = event.target;
    const filteredOrder = orderList.find((order) => order.order_id === value);
    setOrderDetails({ ...orderDetails, [name]: value, ac_name: '', ac_id: '', order_data: [] });
    if (filteredOrder.order_id != '-- NEW --') {
      raiseGetOrderRequest(value);
    } else {
      setIsUpdateRequest(false);
    }
    setPlanProductQtyDetails({});
  };

  const handleSwitchChange = (event, subData, prodId, itemId) => {
    const oldQtyValue = subData['quantity'];
    let price = subData.buy_price;
    if (subData.qty_calc_sp == true) {
      price = subData.sell_price;
    }
    const oldSerial = getPlanSerial(prodId, itemId, price, subData.model_id, subData.brand_id);
    subData.qty_calc_sp = event.target.checked;
    notifyOrderDetailsChange();
    updateQtyCache(subData, prodId, itemId, oldSerial, oldQtyValue, subData.model_id, subData.brand_id);
  };

  const handleSupplierNameChange = (value) => {
    if (validateStringForNull(value) && validateStringForNull(value.ac_id)) {
      setOrderDetails({ ...orderDetails,
        ac_id: value.ac_id,
        ac_name: value.ac_name
      });
    } else {
      setOrderDetails({ ...orderDetails,
        ac_id: '',
        ac_name: ''
      });
    }
  };

  const setProductName = (id, response) => {
    const dataObj= orderDetails.order_data.find((item) => item.id === id);
    if (dataObj != undefined && response.length > 0) {
      const { prod_id: prodId, prod_name: prodName } = response[0];
      dataObj.prod_id = prodId;
      dataObj.prod_name = prodName;
      notifyOrderDetailsChange();
    }
  };

  const handleAddMarkClick = (mark) => {
    const material = MATERIAL_TYPE[mark.material_type];
    const lastIdData = orderDetails.order_data.length > 0 ? orderDetails.order_data[orderDetails.order_data.length - 1].id : 0;
    let newSubObj = {};
    let newObj = {};
    if (material == 3) {
      newObj = readyMadeDefaultObject;
      newSubObj = readyMadeSubDataDefaultObject;
    } else if (material == 2) {
      newObj = silkDefaultObject;
      newSubObj = silkSubDataDefaultObject;
    } else if (material == 1) {
      newObj = polyesterDefaultObject;
      newSubObj = polyesterSubDataDefaultObject;
    }
    newSubObj.id = 1;
    newObj.id = lastIdData + 1;
    newObj.sub_data = [newSubObj];
    newObj.item_id = mark.item_id;
    newObj.material_type = material;
    newObj.mark_name = mark.mark_name;
    orderDetails.order_data.push(newObj);
    notifyOrderDetailsChange();
  };

  const handleAddDataClick = (order) => {
    let newSubObj;

    if (order.material_type == 1) {
      newSubObj = polyesterSubDataDefaultObject;
    } else if (order.material_type == 2) {
      newSubObj = silkSubDataDefaultObject;
    } else {
      newSubObj = readyMadeSubDataDefaultObject;
    }
    newSubObj.id = 1;
    let newObj;

    if (order.material_type == 3) {
      newObj = {
        ...readyMadeDefaultObject,
        item_id: order.item_id,
        material_type: order.material_type,
        mark_name: order.mark_name,
        prod_id: order.prod_id,
        prod_name: order.prod_name,
        cate_id: order.cate_id,
        cate_name: order.cate_name,
        id: orderDetails.order_data.length > 0 ? orderDetails.order_data[orderDetails.order_data.length - 1].id + 1 : 1,
        sub_data: [newSubObj]
      };
    } else if (order.material_type == 2) {
      newObj = {
        ...silkDefaultObject,
        item_id: order.item_id,
        material_type: order.material_type,
        mark_name: order.mark_name,
        prod_id: order.prod_id,
        prod_name: order.prod_name,
        id: orderDetails.order_data.length > 0 ? orderDetails.order_data[orderDetails.order_data.length - 1].id + 1 : 1,
        sub_data: [newSubObj]
      };
    } else {
      newObj = {
        ...polyesterDefaultObject,
        item_id: order.item_id,
        material_type: order.material_type,
        mark_name: order.mark_name,
        prod_id: order.prod_id,
        prod_name: order.prod_name,
        id: orderDetails.order_data.length > 0 ? orderDetails.order_data[orderDetails.order_data.length - 1].id + 1 : 1,
        sub_data: [newSubObj]
      };
    }
    orderDetails.order_data.push(newObj);
    notifyOrderDetailsChange();
  };

  const handleAutoCompleteChange = (value, data, idField, nameField) => {
    data[idField] = value != null ? value[idField] : '';
    data[nameField] = value != null ? value[nameField] : '';
    if (idField === 'cate_id') {
      data.prod_id = '';
      data.prod_name = '';
    }
    notifyOrderDetailsChange();
    if (value != null) {
      if (idField == 'prod_id' && planProductQtyDetails[generateQtyObjKey(data.item_id, value[idField])] == undefined) {
        raiseGetOrderQuantity(value[idField], data.item_id);
      }
    }
  };

  const handleSubdataAutoCompleteChange = (value, subData, idField, nameField, prodId, itemId) => {
    const oldQtyValue = subData['quantity'];
    const price = subData.buy_price;
    const oldSerial = getPlanSerial(prodId, itemId, price, subData.model_id, subData.brand_id);
    subData[idField] = value != null ? value[idField] : '';
    subData[nameField] = value != null ? value[nameField] : '';
    notifyOrderDetailsChange();
    updateQtyCache(subData, prodId, itemId, oldSerial, oldQtyValue, subData.model_id, subData.brand_id);
  };

  const handleAddSubDataClick = (data) => {
    const lastId = data.sub_data[data.sub_data.length - 1].id;
    let newSubObj = {};
    if (data.material_type == 3) {
      newSubObj = readyMadeSubDataDefaultObject;
    } else if (data.material_type == 2) {
      newSubObj = silkSubDataDefaultObject;
    } else if (data.material_type == 1) {
      newSubObj = polyesterSubDataDefaultObject;
    }
    newSubObj.id = lastId + 1;
    data.sub_data.push(newSubObj);
    notifyOrderDetailsChange();
  };

  const handleResetClick = () => {
    const cloned = cloneDeep(originalValue);
    setOrderDetails(cloned);
  };

  const getPlanBoughtQty = (itemId, prodId, price, qty, modelId, branId, subData) => {
    const obj = getQtyObjFromCacheUsingPrice(prodId, itemId, price, modelId, branId);
    if (obj != undefined && obj != null) {
      let quantity = qty;
      if (!validateNumber(quantity)) {
        quantity = 0;
      }
      const newQtyValue = obj.bought_quantity;
      return newQtyValue + '/' + obj.planned_quantity;
    }
    return 'NA';
  };

  const notifyOrderDetailsChange = () => {
    setOrderDetails({ ...orderDetails, refresh_id: orderDetails.refresh_id + 1 });
  };

  const handleRemoveSubDataClick = (data, subDataId) => {
    const subDataToRemove = data.sub_data.find((subItem) => subItem.id === subDataId);
    if (subDataToRemove != undefined) {
      const price = subDataToRemove.qty_calc_sp == true ? subDataToRemove.sell_price : subDataToRemove.buy_price;
      const obj = getQtyObjFromCacheUsingPrice(data.prod_id, data.item_id, price, subDataToRemove.model_id, subDataToRemove.brand_id);
      if (obj == undefined) {
        return;
      }
      obj.bought_quantity -= subDataToRemove.quantity;
    }
    data.sub_data = data.sub_data.filter((subItem) => subItem.id !== subDataId);
    notifyOrderDetailsChange();
  };

  const handleRemoveDataClick = (id) => {
    const orderDataToRemove = orderDetails.order_data.find((item) => item.id === id);
    if (orderDataToRemove != undefined) {
      orderDataToRemove.sub_data.forEach((subData) => {
        const price = subData.qty_calc_sp == true ? subData.sell_price : subData.buy_price;
        const obj = getQtyObjFromCacheUsingPrice(orderDataToRemove.prod_id, orderDataToRemove.item_id,
          price, orderDataToRemove.model_id, orderDataToRemove.branId);
        if (obj !== undefined) {
          obj.bought_quantity -= subData.quantity;
        }
      });
    }
    orderDetails.order_data = orderDetails.order_data.filter((item) => item.id !== id);
    notifyOrderDetailsChange();
  };

  const handleProductIdChange = (event, data) => {
    const { name, value } = event.target;
    data[name] = value.toUpperCase();
    if (!validateStringForNull(value)) {
      data.prod_name = '';
    }
    notifyOrderDetailsChange();
  };

  const handleSubDataTextChange = (event, subData) => {
    const { name, value } = event.target;
    subData[name] = value.toUpperCase();
    notifyOrderDetailsChange();
  };

  const handleSubDataNumberChange = (event, subData, prodId, itemId) => {
    const { name, value } = event.target;
    if (validateStringForNull(value) && !validateNumber(value) ) {
      return;
    }
    const oldQtyValue = subData['quantity'];
    let price = subData.buy_price;
    if (subData.qty_calc_sp == true) {
      price = subData.sell_price;
    }
    const oldSerial = getPlanSerial(prodId, itemId, price, subData.model_id, subData.brand_id);
    if (name === 'no_of_sets') {
      if (validateNumber(value)) {
        subData.no_of_sets = value;
        if (validateNumber(subData.qty_per_set)) {
          subData.quantity = value * subData.qty_per_set;
        } else {
          subData.quantity = 0;
        }
      } else {
        subData.quantity = 0;
      }
    } else if (name === 'qty_per_set') {
      if (validateNumber(value)) {
        subData.qty_per_set = value;
        if (validateNumber(subData.no_of_sets)) {
          subData.quantity = value * subData.no_of_sets;
        } else {
          subData.quantity = 0;
        }
      } else {
        subData.quantity = 0;
      }
    } else {
      subData[name] = value == '' ? '' : Number(value);
    }
    notifyOrderDetailsChange();
    updateQtyCache(subData, prodId, itemId, oldSerial, oldQtyValue, subData.model_id, subData.brand_id);
  };

  const updateQtyCache = (subData, prodId, itemId, oldSerial, oldQtyValue, modelId, brandId) => {
    let price = subData.buy_price;
    if (subData.qty_calc_sp == true) {
      price = subData.sell_price;
    }
    let oldObj = undefined;
    if (oldSerial != -1) {
      oldObj = getQtyObjFromCacheUsingSerial(prodId, itemId, oldSerial, modelId, brandId);
    }
    const newSerial = getPlanSerial(prodId, itemId, price, modelId, brandId);
    let newObj = undefined;
    if (newSerial != -1 ) {
      newObj = getQtyObjFromCacheUsingSerial(prodId, itemId, newSerial, modelId, brandId);
    }
    if (newSerial == oldSerial) {
      if (validateNumber(oldQtyValue) && newObj != undefined) {
        newObj.bought_quantity -= oldQtyValue;
      }
    } else {
      if (validateNumber(oldQtyValue) && oldObj != undefined) {
        oldObj.bought_quantity -= oldQtyValue;
      }
    }
    if (validateNumber(subData.quantity) && newObj != undefined) {
      newObj.bought_quantity += subData.quantity;
    }
  };

  const getProductNameSearchParams = (cateId) => {
    if (validateStringForNull(cateId)) {
      return 'cate_id='+ cateId;
    }
    return '';
  };

  const validateOrderDataArray = (params) => {
    const validatedOrders = params.order_data.map((plan, index) => {
      if (plan.material_type == 1) {
        return validatePolyesterOrderData(plan);
      } else if (plan.material_type == 2) {
        return validateSilkOrderData(plan);
      } else if (plan.material_type == 3) {
        return validateReadyMadeOrderData(plan);
      }
    });
    return validatedOrders;
  };

  const handleCreateOrUpdateOrderRequest = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(orderDetails);
      const filteredParamsArray = validateOrderDataArray(params).flat();
      const filteredParams = {
        ...validateOrderDetails(params),
        order_data: filteredParamsArray
      };
      raiseCreateOrUpdateOrderRequest(filteredParams);
    } catch (err) {
      showSnackBar('error', err.message ?? (isUpdateRequest ? 'Failed to Update Order' : 'Failed to Create Order'));
      setIsLoading(false);
    }
  };

  const validateAndGetAttachmentsForCreateUpdate = (attachments) => {
    const imgs = [];
    if (attachments == undefined) return imgs;
    Object.keys(attachments).map((key) => {
      if (!validateStringForNull(attachments[key].attachment_id)) {
        let err = 'Please wait until the attachments finish uploading.';
        err+= ' For rows with a warning sign, click the upload button again for each image to retry uploading';
        throw new Error(err);
      }
      imgs.push(attachments[key].attachment_id);
    });
    return imgs;
  };

  const validateOrderDetails = (params) => {
    const finalParams ={};
    if (isUpdateRequest) {
      if (!validateStringForNull(params.order_id)) {
        throw new Error('Select valid Order Id');
      }
      finalParams.order_id = params.order_id;
    }
    if (!validateStringForNull(params.plan_id)) {
      throw new Error('Select valid Plan Id');
    }
    if (!validateStringForNull(params.ac_id)) {
      throw new Error('Select valid Supplier Name');
    }

    finalParams.plan_id = params.plan_id;
    finalParams.ac_id = params.ac_id;

    return finalParams;
  };

  const validateSilkOrderData = (plan) => {
    if (!validateStringForNull(plan.item_id)) {
      throw new Error('Select valid Mark');
    }
    if (!validateStringForNull(plan.prod_id)) {
      throw new Error('Select valid Product');
    }
    const orderDataArray = [];
    plan.sub_data.map((subData) => {
      if (!validateStringForNull(subData.buy_price)) {
        throw new Error('Provide valid Buy Price');
      }
      if (!validateStringForNull(subData.sell_price)) {
        throw new Error('Provide valid Sell Price');
      }
      if (!validateStringForNull(subData.quantity)) {
        throw new Error('Provide valid Buy Quantity');
      }
      const imgs = validateAndGetAttachmentsForCreateUpdate(subData.attachments);
      const orderDataObj = {
        prod_id: plan.prod_id,
        item_id: plan.item_id,
        plan_serial: getPlanSerial(plan.prod_id, plan.item_id, subData.qty_calc_sp == true ? subData.sell_price : subData.buy_price),
        buy_price: parseFloat(subData.buy_price),
        sell_price: parseFloat(subData.sell_price),
        quantity: parseInt(subData.quantity),
        qty_calc_sp: subData.qty_calc_sp
      };
      if (imgs.length > 0) {
        orderDataObj.attachments = imgs;
      }
      if (validateStringForNull(subData.description)) {
        orderDataObj.description = subData.description;
      }
      if (validateStringForNull(subData.vrty_id)) {
        orderDataObj.vrty_id = subData.vrty_id;
      }
      if (orderDataObj.plan_serial == -1) {
        delete orderDataObj.plan_serial;
      }
      orderDataArray.push(orderDataObj);
    });

    return orderDataArray;
  };

  const validateReadyMadeOrderData = (plan) => {
    if (!validateStringForNull(plan.item_id)) {
      throw new Error('Select valid Mark');
    }
    if (!validateStringForNull(plan.cate_id)) {
      throw new Error('Select valid Category');
    }
    if (!validateStringForNull(plan.prod_id)) {
      throw new Error('Select valid Product');
    }
    const orderDataArray = [];
    plan.sub_data.forEach((subData) => {
      if (!validateStringForNull(subData.buy_price)) {
        throw new Error('Provide valid Buy Price');
      }
      if (!validateStringForNull(subData.quantity)) {
        throw new Error('Provide valid Buy Quantity');
      }
      const imgs = validateAndGetAttachmentsForCreateUpdate(subData.attachments);
      const obj = {
        item_id: plan.item_id,
        cate_id: plan.cate_id,
        prod_id: plan.prod_id,
        plan_serial: getPlanSerial(plan.prod_id, plan.item_id, subData.buy_price, subData.model_id, subData.brand_id),
        buy_price: parseFloat(subData.buy_price),
        quantity: parseInt(subData.quantity)
      };
      if (imgs.length > 0) {
        obj.attachments = imgs;
      }
      if (validateStringForNull(subData.size_id)) {
        obj.size_id = subData.size_id;
      }
      if (validateStringForNull(subData.model_id)) {
        obj.model_id = subData.model_id;
      }
      if (validateStringForNull(subData.brand_id)) {
        obj.brand_id = subData.brand_id;
      }
      if (obj.plan_serial == -1) {
        delete obj.plan_serial;
      }
      orderDataArray.push(obj);
    });

    return orderDataArray;
  };

  const validatePolyesterOrderData = (plan) => {
    if (!validateStringForNull(plan.item_id)) {
      throw new Error('Select valid Mark');
    }
    if (!validateStringForNull(plan.prod_id)) {
      throw new Error('Select valid Product');
    }
    const orderDataArray = [];
    plan.sub_data.forEach((subData) => {
      if (!validateStringForNull(subData.buy_price)) {
        throw new Error('Provide valid Buy Price');
      }
      if (!validateStringForNull(subData.no_of_sets)) {
        throw new Error('Provide valid No Of Sets');
      }
      if (!validateStringForNull(subData.qty_per_set)) {
        throw new Error('Provide valid Quan Per Sets');
      }
      if (!validateStringForNull(subData.quantity)) {
        throw new Error('Provide valid Buy Quantity');
      }
      const imgs = validateAndGetAttachmentsForCreateUpdate(subData.attachments);
      const obj = {
        item_id: plan.item_id,
        prod_id: plan.prod_id,
        plan_serial: getPlanSerial(plan.prod_id, plan.item_id, subData.buy_price),
        buy_price: parseFloat(subData.buy_price),
        no_of_sets: parseInt(subData.no_of_sets),
        qty_per_set: parseInt(subData.qty_per_set),
        quantity: parseInt(subData.quantity)
      };
      if (imgs.length > 0) {
        obj.attachments = imgs;
      }
      if (obj.plan_serial == -1) {
        delete obj.plan_serial;
      }
      orderDataArray.push(obj);
    });

    return orderDataArray;
  };

  const getPlanSerial = (prodId, itemId, price, modelId, brandId) => {
    const obj = getQtyObjFromCacheUsingPrice(prodId, itemId, price, modelId, brandId);
    if (obj != undefined && obj != null ) {
      return obj.serial;
    }
    return -1;
  };

  const generateQtyObjKey = (itemId, prodId) => {
    return `${itemId}-${prodId}`;
  };

  const getQtyObjFromCacheUsingPrice = (prodId, itemId, price, modelId, brandId) => {
    const key = generateQtyObjKey(itemId, prodId);
    if (!validateStringForNull(price)) {
      return undefined;
    }
    if (!validateStringForNull(modelId)) {
      modelId = undefined;
    }
    if (!validateStringForNull(brandId)) {
      brandId = undefined;
    }
    if (planProductQtyDetails[key] != undefined) {
      const obj = planProductQtyDetails[key].find((item) => {
        const priceMatch = (price >= item.min_price && price <= item.max_price);
        const modelMatch = modelId === item.model_id;
        const brandMatch = brandId === item.brand_id;

        return priceMatch && modelMatch && brandMatch;
      });
      return obj;
    }
    return undefined;
  };

  const getQtyObjFromCacheUsingSerial = (prodId, itemId, serial) => {
    const key = generateQtyObjKey(itemId, prodId);
    if (planProductQtyDetails[key] != undefined) {
      const obj = planProductQtyDetails[key].find((item) => serial == item.serial);
      return obj;
    }
    return undefined;
  };

  const raiseCalculateSellPriceRequest = (subData, prodId, itemId) => {
    if (!validateStringForNull(subData.buy_price) || !validateStringForNull(prodId)) {
      return;
    }
    setIsLoading(true);
    const params = 'prod_id=' + prodId + '&buy_price=' + subData.buy_price;
    Services.sendBackendRequest({ apiData: APIData.calculateSellPrice, params: params },
      APIToken.CALCULATE_SELL_PRICE, processSuccess, processError, { subData: subData });
  };

  const raiseUploadImage = (dataId, subdataId, img, showSnackBar) => {
    const data = orderDetails.order_data.find((obj) => obj.id == dataId);
    const subdata = data.sub_data.find((obj) => obj.id == subdataId);
    subdata.attachments[img.name].status = ATTACHMENT_STATUS.UPLOADING;
    notifyOrderDetailsChange();
    const formData = new FormData();
    formData.append('item_id', data.item_id);
    formData.append('attachments', img);
    Services.sendMultipartRequest({ apiData: APIData.uploadAttachments, params: formData },
      APIToken.UPLOAD_ATTACHMENTS, processSuccess, processError,
      { dataId: dataId, subdataId: subdataId, imageKey: img.name, showSnackBar: showSnackBar });
  };

  const raiseDownloadImage = (dataId, subdataId, imgKey, showSnackBar) => {
    const data = orderDetails.order_data.find((obj) => obj.id == dataId);
    const subdata = data.sub_data.find((obj) => obj.id == subdataId);
    subdata.attachments[imgKey].status = ATTACHMENT_STATUS.DOWNLOADING;
    notifyOrderDetailsChange();
    const params = 'order_id=' + orderDetails.order_id + '&serial=' + subdata.serial;
    Services.sendBackendRequest({ apiData: APIData.downloadAttachment, uriValues: [subdata.attachments[imgKey].attachment_id], params: params },
      APIToken.DOWNLOAD_ATTACHMENT, processSuccess, processError,
      { dataId: dataId, subdataId: subdataId, imageKey: imgKey, showSnackBar: showSnackBar });
  };

  // const raiseDownloadImages = (dataId, subdataId, imgKeys, showSnackBar) => {
  //   const data = orderDetails.order_data.find((obj) => obj.id == dataId);
  //   const subdata = data.sub_data.find((obj) => obj.id == subdataId);
  //   const attachmentIds = [];
  //   imgKeys.map((key) => {
  //     subdata.attachments[key].status = ATTACHMENT_STATUS.DOWNLOADING;
  //     attachmentIds.push(subdata.attachments[key].attachment_id);
  //   });
  //   notifyOrderDetailsChange();
  //   const params = 'attachment_ids=' + attachmentIds.join(',') + '&order_id=' + orderDetails.order_id + '&serial=' + subdata.serial;
  //   Services.sendBackendRequest({ apiData: APIData.downloadAttachments, params: params },
  //     APIToken.DOWNLOAD_ATTACHMENTS, processSuccess, processError,
  // { dataId: dataId, subdataId: subdataId, imageKeys: imgKeys, showSnackBar: showSnackBar });
  // };

  const handleShowPlanDetails = (data) => {
    data.is_expanded = !data.is_expanded;
    notifyOrderDetailsChange();
  };

  const getVarietyNameSearchParams = (prodId) => {
    if (validateStringForNull(prodId)) {
      return 'prod_id='+prodId;
    }
    return '';
  };

  const handleAddPhotoClick = (dataId, subdataId) => {
    const sdata = findSubData(dataId, subdataId);
    if (sdata != null && sdata != undefined) {
      setCameraParams({ dataId: dataId, subdataId: subdataId, images: sdata.attachments });
      setIsCameraDialogOpen(true);
    }
  };

  const findSubData = (dataId, subdataId) => {
    const data = orderDetails.order_data.find((obj) => obj.id == dataId);
    if (data != null && data != undefined) {
      const subdata = data.sub_data.find((obj) => obj.id == subdataId);
      return subdata;
    }
    return undefined;
  };

  const handleGalleryClick = (dataId, subdataId) => {
    const sdata = findSubData(dataId, subdataId);
    if (sdata != null && sdata != undefined) {
      setCameraParams({ dataId: dataId, subdataId: subdataId, images: sdata.attachments });
      setIsGalleryOpen(true);
      // const ids= [];
      Object.keys(sdata.attachments).map((key) => {
        if (sdata.attachments[key].data == undefined) {
          // ids.push(key);
          handleDownloadPic(dataId, subdataId, key, false);
        }
      });
      // if (ids.length > 0) {
      //   try {
      //     raiseDownloadImages(dataId, subdataId, ids);
      //   } catch (err) {
      //     console.log(err);
      //   }
      // }
    }
  };

  const handleDownloadPic = (dataId, subdataId, imgKey, showSnackBar) => {
    if (imgKey != undefined) {
      try {
        raiseDownloadImage(dataId, subdataId, imgKey, showSnackBar);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleImageCapture = (img) => {
    const sdata = findSubData(cameraParams.dataId, cameraParams.subdataId);
    if (sdata != null && sdata != undefined) {
      const key = cameraParams.dataId.toString() + cameraParams.subdataId.toString() + moment().valueOf().toString() + '.png';
      const file = new File([img], key, { type: 'image/png' });
      sdata.attachments[key] = {};
      sdata.attachments[key].data = file;
      notifyOrderDetailsChange();
      try {
        raiseUploadImage(cameraParams.dataId, cameraParams.subdataId, file, false);
      } catch (err) {
        console.log(err);
      }
    }
    setIsCameraDialogOpen(false);
  };

  const handleImageDelete = (dataId, subdataId, key) => {
    const sdata = findSubData(dataId, subdataId);
    if (sdata != null && sdata != undefined) {
      delete sdata.attachments[key];
      notifyOrderDetailsChange();
    }
  };

  const getIsAttachmentsUploadPending = (imgs) => {
    let uploadPending = false;
    if (imgs == undefined) return uploadPending;
    Object.keys(imgs).map((key) => {
      if (!validateStringForNull(imgs[key].attachment_id)) {
        uploadPending = true;
      }
    });
    return uploadPending;
  };

  const getReadyMadeContent = (order) => {
    return (
      <Grid item xs={12} key={order.id}>
        <Card elevation={12}>
          <Grid key={order.id} container>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
              sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
              <Grid item xs={2.8} sm={1.2} md={1.1}>
                <TextField
                  id="mark_name"
                  name="mark_name"
                  autoComplete ='off'
                  variant='outlined'
                  value={order.mark_name}
                  size='small'
                  fullWidth
                  sx={{ backgroundColor: '#205295', borderRadius: '7px' }}
                  InputProps={{
                    style: { color: 'white' },
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={9.2} sm={4.4} md={4.5}>
                <CustomAutocomplete
                  label={'Category Name'}
                  apiInfo={{
                    searchURI: APIData.getCategory,
                    searchField: 'cate_name',
                    idField: 'cate_id'
                  }}
                  defaultValue={{
                    cate_id: order.cate_id,
                    cate_name: order.cate_name
                  }}
                  onSelectionChange={(event, value) =>
                    handleAutoCompleteChange(value, order, 'cate_id', 'cate_name')}
                />
              </Grid>
              <Grid item xs={7.8} sm={4.4} md={4.5}>
                <CustomAutocomplete
                  label={'Product Name'}
                  apiInfo={{
                    searchURI: APIData.getProductName,
                    searchField: 'prod_name',
                    idField: 'prod_id',
                    searchParams: getProductNameSearchParams(order.cate_id)
                  }}
                  defaultValue={{
                    prod_id: order.prod_id,
                    prod_name: order.prod_name
                  }}
                  onSelectionChange={(event, value) =>
                    handleAutoCompleteChange(value, order, 'prod_id', 'prod_name')}
                />
              </Grid>
              <Grid item xs={4.2} sm={2} md={1.9} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                <IconButton onClick={() => handleAddDataClick(order)} color='primary' size='small'>
                  <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                </IconButton>
                <IconButton
                  onClick={() => handleRemoveDataClick(order.id)} color='error' size='small' sx={{ p: 1 }}>
                  <DeleteIcon/>
                </IconButton>
                <IconButton color='inherit' onClick={() => handleShowPlanDetails(order)}>
                  {order.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                </IconButton>
              </Grid>
            </Grid>
            {order.is_expanded == true ? order.sub_data.map((planSubData, j) => (
              <Grid container item xs={12} key={planSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                <Grid item container xs={12} lg={5} rowSpacing={2} columnSpacing={2} >
                  <Grid item xs={12} sm={5} lg={6}>
                    <CustomAutocomplete
                      label={'Model Name'}
                      apiInfo={{
                        searchURI: APIData.getModel,
                        searchField: 'model_name',
                        idField: 'model_id'
                      }}
                      defaultValue={{
                        model_id: planSubData.model_id,
                        model_name: planSubData.model_name
                      }}
                      onSelectionChange={(event, value) =>
                        handleSubdataAutoCompleteChange(value, planSubData, 'model_id', 'model_name', order.prod_id, order.item_id)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5} lg={6}>
                    <CustomAutocomplete
                      label={'Brand Name'}
                      apiInfo={{
                        searchURI: APIData.getBrand,
                        searchField: 'brand_name',
                        idField: 'brand_id'
                      }}
                      defaultValue={{
                        brand_id: planSubData.brand_id,
                        brand_name: planSubData.brand_name
                      }}
                      onSelectionChange={(event, value) =>
                        handleSubdataAutoCompleteChange(value, planSubData, 'brand_id', 'brand_name', order.prod_id, order.item_id)}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={12} lg={7} rowSpacing={2} columnSpacing={2} >
                  <Grid item xs={4} sm={2} lg={1.5}>
                    <TextField
                      id="size"
                      label="Size"
                      name="size_id"
                      autoComplete ='off'
                      value={planSubData.size_id}
                      variant='standard'
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4} sm={3} lg={3}>
                    <TextField
                      id="buy_price"
                      label="Buy Price"
                      name="buy_price"
                      autoComplete ='off'
                      variant='standard'
                      type='number'
                      value={planSubData.buy_price}
                      onChange={(event) => handleSubDataNumberChange(event, planSubData, order.prod_id, order.item_id)}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4} sm={2} lg={2}>
                    <TextField
                      id="bought_quantity"
                      label="B/P Qty"
                      name="bought_quantity"
                      autoComplete ='off'
                      variant='standard'
                      value={getPlanBoughtQty(order.item_id, order.prod_id,
                        planSubData.buy_price, planSubData.quantity, planSubData.model_id, planSubData.brand_id)}
                      disabled={true}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} lg={2}>
                    <TextField
                      id="buy_quantity"
                      label="Buy Quantity"
                      name="quantity"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.quantity}
                      onChange={(event) => handleSubDataNumberChange(event, planSubData, order.prod_id, order.item_id)}
                      size='small'
                      fullWidth
                      type='phone'
                    />
                  </Grid>
                  <Grid item xs={6} sm={2} lg={3.5} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                    {j === order.sub_data.length - 1 && (
                      <IconButton onClick={() => handleAddSubDataClick(order)} color='primary' size='small'>
                        <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                      </IconButton>
                    )}
                    {order.sub_data.length > 1 ? (
                      <IconButton onClick={() => handleRemoveSubDataClick(order, planSubData.id)} color='error' size='small'>
                        <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                      </IconButton>
                    ) : ''}
                    <Badge badgeContent={planSubData.attachments != undefined ? Object.keys(planSubData.attachments).length : 0}
                      showZero color="success" overlap="circular">
                      <IconButton onClick={() => handleGalleryClick(order.id, planSubData.id)} color='success' size='small'>
                        <AddAPhotoIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                      </IconButton>
                    </Badge>
                    {getIsAttachmentsUploadPending(planSubData.attachments) ? (
                      <IconButton color='warning' size='small'>
                        <WarningIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                      </IconButton>
                    ) : ''}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )) : ''}
          </Grid>
        </Card>
      </Grid>
    );
  };


  const getSilkContent = (order) => {
    return (
      <Grid item xs={12} key={order.id}>
        <Card elevation={12}>
          <Grid key={order.id} container>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
              sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
              <Grid item xs={2.8} sm={1.2} md={1.1}>
                <TextField
                  id="mark_name"
                  name="mark_name"
                  autoComplete ='off'
                  variant='outlined'
                  value={order.mark_name}
                  size='small'
                  fullWidth
                  sx={{ backgroundColor: '#205295', borderRadius: '7px' }}
                  InputProps={{
                    style: { color: 'white' },
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={9.2} sm={4.4} md={4.5}>
                <TextField
                  id="prod_id"
                  label="Product Id"
                  name="prod_id"
                  autoComplete ='off'
                  sx={{ backgroundColor: 'white' }}
                  value={order.prod_id}
                  onChange={(event) => handleProductIdChange(event, order)}
                  size='small'
                  fullWidth
                  onBlur={() => handleProductDetailsSearch(order.id, order.prod_id, order.item_id)}
                />
              </Grid>
              <Grid item xs={7.8} sm={4.4} md={4.5}>
                <TextField
                  id="prod_name"
                  label="Product Name"
                  name="prod_id"
                  autoComplete ='off'
                  sx={{ backgroundColor: 'white' }}
                  value={order.prod_name}
                  size='small'
                  fullWidth
                  disabled={true}
                />
              </Grid>
              <Grid item xs={4.2} sm={2} md={1.9} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                <IconButton onClick={() => handleAddDataClick(order)} color='primary' size='small'>
                  <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                </IconButton>
                <IconButton
                  onClick={() => handleRemoveDataClick(order.id)} color='error' size='small' sx={{ p: 1 }}>
                  <DeleteIcon/>
                </IconButton>
                <IconButton color='inherit' onClick={() => handleShowPlanDetails(order)}>
                  {order.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                </IconButton>
              </Grid>
            </Grid>
            {order.is_expanded == true ? order.sub_data.map((planSubData, j) => (
              <Grid container item xs={12} key={planSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                <Grid item xs={9} sm={4.5} md={4.5}>
                  <CustomAutocomplete
                    label={'Variety'}
                    apiInfo={{
                      searchURI: APIData.getVariety,
                      searchField: 'vrty_name',
                      idField: 'vrty_id',
                      searchParams: getVarietyNameSearchParams(order.prod_id)
                    }}
                    defaultValue={{
                      vrty_id: planSubData.vrty_id,
                      vrty_name: planSubData.vrty_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleAutoCompleteChange(value, planSubData, 'vrty_id', 'vrty_name')}
                  />
                </Grid>
                <Grid item xs={4.5} sm={3} md={3}>
                  <TextField
                    id="buy_price"
                    label="Buy Price"
                    name="buy_price"
                    autoComplete ='off'
                    variant='standard'
                    type='number'
                    value={planSubData.buy_price}
                    onChange={(event) => handleSubDataNumberChange(event, planSubData, order.prod_id, order.item_id)}
                    size='small'
                    fullWidth
                    onBlur={(event, value) => raiseCalculateSellPriceRequest(planSubData, order.prod_id, order.item_id)}
                  />
                </Grid>
                <Grid item xs={3} sm={1.5} md={1.5} lg={1.5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Switch
                    onChange={(event) =>handleSwitchChange(event, planSubData, order.prod_id, order.item_id)}
                    checked={planSubData.qty_calc_sp}
                    sx={{
                      '& .MuiSwitch-thumb': {
                        color: '#205295;'
                      },
                      '& .MuiSwitch-track': {
                        backgroundColor: '#172b4d'
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={4.5} sm={3} md={3}>
                  <TextField
                    id="sell_price"
                    label="Sell Price"
                    name="sell_price"
                    autoComplete ='off'
                    variant='standard'
                    type='number'
                    value={planSubData.sell_price}
                    onChange={(event) => handleSubDataNumberChange(event, planSubData, order.prod_id, order.item_id)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => raiseCalulateSellPriceRequest(planSubData, order.prod_id)}
                            onMouseDown={(e) => e.preventDefault()}
                            onMouseUp={(e) => e.preventDefault()}
                            edge="end"
                          >
                            <CachedIcon/>
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={7.5} sm={4.5} md={4.5} lg={4.5}>
                  <TextField
                    id="description"
                    label="Description"
                    name="description"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.description}
                    onChange={(event) => handleSubDataTextChange(event, planSubData)}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4.5} sm={2.5} md={2.5} lg={2.5}>
                  <TextField
                    id="bought_quantity"
                    label="B/P Qty"
                    name="bought_quantity"
                    autoComplete ='off'
                    variant='standard'
                    value={getPlanBoughtQty(order.item_id, order.prod_id,
                      planSubData.qty_calc_sp == true ? planSubData.sell_price : planSubData.buy_price, planSubData.quantity)}
                    onChange={(event) => handleSubDataTextChange(event, planSubData)}
                    size='small'
                    disabled={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={2.3} md={2.3}>
                  <TextField
                    id="buy_quantity"
                    label="Buy Quantity"
                    name="quantity"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.quantity}
                    onChange={(event) => handleSubDataNumberChange(event, planSubData, order.prod_id, order.item_id)}
                    size='small'
                    fullWidth
                    type='phone'
                  />
                </Grid>
                <Grid item xs={6} sm={2.7} md={2.7} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                  {j === order.sub_data.length - 1 && (
                    <IconButton onClick={() => handleAddSubDataClick(order)} color='primary' size='small'>
                      <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                    </IconButton>
                  )}
                  {order.sub_data.length > 1 ? (
                    <IconButton onClick={() => handleRemoveSubDataClick(order, planSubData.id)} color='error' size='small'>
                      <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  ) : ''}
                  <Badge badgeContent={planSubData.attachments != undefined ? Object.keys(planSubData.attachments).length : 0}
                    showZero color="success" overlap="circular">
                    <IconButton onClick={() => handleGalleryClick(order.id, planSubData.id)} color='success' size='small'>
                      <AddAPhotoIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  </Badge>
                  {getIsAttachmentsUploadPending(planSubData.attachments) ? (
                    <IconButton color='warning' size='small'>
                      <WarningIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  ) : ''}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )) : ''}
          </Grid>
        </Card>
      </Grid>
    );
  };

  const getPolyesterContent = (order) => {
    return (
      <Grid item xs={12} key={order.id}>
        <Card elevation={12}>
          <Grid key={order.id} container>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
              sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
              <Grid item xs={2.8} sm={1.2} md={1.1}>
                <TextField
                  id="mark_name"
                  name="mark_name"
                  autoComplete ='off'
                  variant='outlined'
                  value={order.mark_name}
                  size='small'
                  fullWidth
                  sx={{ backgroundColor: '#205295', borderRadius: '7px' }}
                  InputProps={{
                    style: { color: 'white' },
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={9.2} sm={4.4} md={4.5}>
                <TextField
                  id="prod_id"
                  label="Product Id"
                  name="prod_id"
                  autoComplete ='off'
                  sx={{ backgroundColor: 'white' }}
                  value={order.prod_id}
                  onChange={(event) => handleProductIdChange(event, order)}
                  size='small'
                  fullWidth
                  onBlur={() => handleProductDetailsSearch(order.id, order.prod_id, order.item_id)}
                />
              </Grid>
              <Grid item xs={7.8} sm={4.4} md={4.5}>
                <TextField
                  id="prod_name"
                  label="Product Name"
                  name="prod_id"
                  autoComplete ='off'
                  sx={{ backgroundColor: 'white' }}
                  value={order.prod_name}
                  size='small'
                  fullWidth
                  disabled={true}
                />
              </Grid>
              <Grid item xs={4.2} sm={2} md={1.9} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                <IconButton onClick={() => handleAddDataClick(order)} color='primary' size='small'>
                  <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                </IconButton>
                <IconButton
                  onClick={() => handleRemoveDataClick(order.id)} color='error' size='small' sx={{ p: 1 }}>
                  <DeleteIcon/>
                </IconButton>
                <IconButton color='inherit' onClick={() => handleShowPlanDetails(order)} >
                  {order.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                </IconButton>
              </Grid>
            </Grid>
            {order.is_expanded == true ? order.sub_data.map((planSubData, j) => (
              <Grid container item xs={12} key={planSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                <Grid item xs={9} sm={3}>
                  <CustomAutocomplete
                    label={'Variety'}
                    apiInfo={{
                      searchURI: APIData.getVariety,
                      searchField: 'vrty_name',
                      idField: 'vrty_id',
                      searchParams: getVarietyNameSearchParams(order.prod_id)
                    }}
                    defaultValue={{
                      vrty_id: planSubData.vrty_id,
                      vrty_name: planSubData.vrty_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleAutoCompleteChange(value, planSubData, 'vrty_id', 'vrty_name')}
                  />
                </Grid>
                <Grid item xs={6} sm={1.5}>
                  <TextField
                    id="buy_price"
                    label="Buy Price"
                    name="buy_price"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.buy_price}
                    onChange={(event) => handleSubDataNumberChange(event, planSubData, order.prod_id, order.item_id)}
                    size='small'
                    fullWidth
                    type='number'
                  />
                </Grid>
                <Grid item xs={6} sm={1.5}>
                  <TextField
                    id="bought_quantity"
                    label="B/P Qty"
                    name="bought_quantity"
                    autoComplete ='off'
                    variant='standard'
                    value={getPlanBoughtQty(order.item_id, order.prod_id, planSubData.buy_price, planSubData.quantity)}
                    onChange={(event) => handleSubDataTextChange(event, planSubData)}
                    size='small'
                    disabled={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={1.3}>
                  <TextField
                    id="no_of_sets"
                    label="No Of Sets"
                    name="no_of_sets"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.no_of_sets}
                    onChange={(event) => handleSubDataNumberChange(event, planSubData, order.prod_id, order.item_id)}
                    size='small'
                    fullWidth
                    type='phone'
                  />
                </Grid>
                <Grid item xs={6} sm={1.3}>
                  <TextField
                    id="qty_per_set"
                    label="Quan Per Set"
                    name="qty_per_set"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.qty_per_set}
                    onChange={(event) => handleSubDataNumberChange(event, planSubData, order.prod_id, order.item_id)}
                    size='small'
                    fullWidth
                    type='phone'
                  />
                </Grid>
                <Grid item xs={6} sm={1.3}>
                  <TextField
                    id="buy_quantity"
                    label="Buy Quantity"
                    name="quantity"
                    autoComplete ='off'
                    variant='standard'
                    type='phone'
                    value={planSubData.quantity}
                    onChange={(event) => handleSubDataNumberChange(event, planSubData, order.prod_id, order.item_id)}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={2.1} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                  {j === order.sub_data.length - 1 && (
                    <IconButton onClick={() => handleAddSubDataClick(order)} color='primary' size='small'>
                      <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                    </IconButton>
                  )}
                  {order.sub_data.length > 1 ? (
                    <IconButton onClick={() => handleRemoveSubDataClick(order, planSubData.id)} color='error' size='small'>
                      <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  ) : ''}
                  <Badge badgeContent={planSubData.attachments != undefined ? Object.keys(planSubData.attachments).length : 0}
                    showZero color="success" overlap="circular">
                    <IconButton onClick={() => handleGalleryClick(order.id, planSubData.id)} color='success' size='small'>
                      <AddAPhotoIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  </Badge>
                  {getIsAttachmentsUploadPending(planSubData.attachments) ? (
                    <IconButton color='warning' size='small'>
                      <WarningIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  ) : ''}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )) : ''}
          </Grid>
        </Card>
      </Grid>
    );
  };

  const getMaterialContent = (order) => {
    switch (order.material_type) {
    case 1:
      return getPolyesterContent(order);
    case 2:
      return getSilkContent(order);
    case 3:
      return getReadyMadeContent(order);
    default:
      return 'Invalid Material type';
    }
  };

  return (
    <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
      <Grid container direction='row' columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography variant='h5' align='center'>
            Orders Booking
          </Typography>
        </Grid>
        <Grid item xs={10} sm={4}>
          <FormControl fullWidth >
            <InputLabel size="small" id="plan-list-select-label"> Plan Id</InputLabel>
            <Select
              labelId="plan-list-select-label"
              id="plan-list-select"
              label="Plan id"
              name='plan_id'
              value={orderDetails.plan_id}
              onChange={(event) => handlePlanChange(event)}
              size="small"
            >
              {planList.map((obj) => (
                <MenuItem key={obj.plan_id} value={obj.plan_id}>
                  {obj.plan_id} {obj.plan_tag ? `- ${obj.plan_tag}` : ''}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1} >
          <Tooltip title='View Plan'>
            <span>
              <IconButton
                sx={{ backgroundColor: 'white' }} color='primary'
                onClick={handleViewPlanClick}
                size='small'
                disabled={!validateStringForNull(orderDetails.plan_id)}
              >
                <VisibilityIcon/>
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth >
            <InputLabel size="small" id="order-list-select-label"> Order Id</InputLabel>
            <Select
              labelId="order-list-select-label"
              id="order-list-select"
              label="Order Id"
              name='order_id'
              value={orderDetails.order_id}
              onChange={(event) => handleOrderChange(event)}
              size="small"
            >
              {orderList.map((obj) => (
                <MenuItem key={obj.order_id} value={obj.order_id}>
                  {obj.order_id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            id="order Date"
            label="Order Date"
            name="order_date"
            autoComplete ='off'
            value={orderDetails.order_date}
            size='small'
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <CustomAutocomplete
            label={'Supplier Name'}
            apiInfo={{
              searchURI: APIData.getSupplier,
              searchField: 'ac_name',
              idField: 'ac_id'
            }}
            defaultValue={{
              ac_id: orderDetails.ac_id,
              ac_name: orderDetails.ac_name
            }}
            onSelectionChange={(event, value) =>
              handleSupplierNameChange(value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {orderDetails.order_data.length > 0 ?
          orderDetails.order_data.map((order, index) => (
            <React.Fragment key={index}>
              {getMaterialContent(order)}
            </React.Fragment>
          )) : ''}
        {validateStringForNull(orderDetails.order_id) ? (
          <Grid item container>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' }, mb: { xs: 1 } }}>
              <Typography>
                {orderDetails.order_data.length > 0 ? 'You can also add' : 'Select Mark'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {markList.map((mark) => (
                <Chip
                  sx={{ m: 1 }}
                  key={mark.item_id}
                  label={mark.mark_name}
                  onClick={() => handleAddMarkClick(mark)}
                />
              ))}
            </Grid>
            {orderDetails.order_data.length > 0 ? (
              <Grid item xs={12} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                <Button
                  type='submit'
                  variant="text"
                  onClick={handleResetClick}
                  sx={{ mr: 2 }}
                >
                  Reset
                </Button>
                <Button
                  type="submit"
                  variant='contained'
                  onClick={handleCreateOrUpdateOrderRequest}
                >
                  {isUpdateRequest == true ? 'Update' : 'Create' }
                </Button>
              </Grid>
            ) : ''}
          </Grid>
        ) : ''}
      </Grid>
      <Dialog
        fullScreen
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }
        }}
        open={isViewPlanDialogOpen}
        TransitionComponent={Transition}
        onClose={() => {}}
        PaperProps={{
          sx: {
            width: {
              lg: '50%'
            }
          }
        }}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setIsViewPlanDialogOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              View Plan Details
            </Typography>
          </Toolbar>
        </AppBar>
        <ViewPlanDetails planId={orderDetails.plan_id} />
      </Dialog>
      <Dialog
        fullScreen
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }
        }}
        open={isCameraDialogOpen}
        TransitionComponent={Transition}
        onClose={() => {}}
        PaperProps={{
          sx: {
            width: {
              lg: '40%'
            }
          }
        }}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setIsCameraDialogOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Image Capture
            </Typography>
          </Toolbar>
        </AppBar>
        <TSCamera isOpen={isCameraDialogOpen} handleImageCapture={(img) => handleImageCapture(img)}/>
      </Dialog>
      <Dialog
        fullScreen
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }
        }}
        open={isGalleryOpen}
        TransitionComponent={Transition}
        onClose={() => {}}
        PaperProps={{
          sx: {
            width: {
              lg: '40%'
            }
          }
        }}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setIsGalleryOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Gallery
            </Typography>
          </Toolbar>
        </AppBar>
        <TSGallery dataId={cameraParams.dataId} subdataId={cameraParams.subdataId}
          images={cameraParams.images} openCamera={handleAddPhotoClick}
          handleAddPic={handleImageCapture} handleRemovePic={handleImageDelete} handleUploadPic={raiseUploadImage}
          handleDownloadPic={handleDownloadPic}/>
      </Dialog>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </Container>
  );
};

export default OrdersBooking;
