import { Constants } from './Constants';

class APIData {
  static apiDataList = [];
  constructor(apiPath, method, appName, isAuthRequired) {
    this.apiPath = appName + '/' + apiPath;
    this.method = method;
    this.isAuthRequired = isAuthRequired ?? true;
  }

  static createAPIData(apiPath, method, appName, isAuthRequired) {
    const apiData = new APIData(apiPath, method, appName, isAuthRequired);
    this.apiDataList.push(apiData);
    return apiData;
  }

  getAPIPath() {
    return this.apiPath;
  }

  getMethod() {
    return this.method;
  }

  getIsAuthRequired() {
    return this.isAuthRequired;
  }

  static getAPIDataByPath(appName, path) {
    const searchPath = appName + '/' + path;
    return this.apiDataList.find((obj) => obj.getAPIPath() == searchPath);
  }

  // ######################### Common APIs #####################################
  static listShops = this.createAPIData('shops', 'GET', Constants.ROOT_APP_KEY);

  // ######################### IAM APIs #####################################
  static listUsers = this.createAPIData('users', 'GET', Constants.IAM_APP_KEY);
  static searchUser = this.createAPIData('users/search', 'GET', Constants.IAM_APP_KEY, false);

  static getUser = this.createAPIData(`users/{0}`, 'GET', Constants.IAM_APP_KEY);
  static modifyUser = this.createAPIData(`users/{0}`, 'PUT', Constants.IAM_APP_KEY);
  static createUser = this.createAPIData(`users`, 'POST', Constants.IAM_APP_KEY);
  static deleteUser = this.createAPIData(`users/{0}`, 'DELETE', Constants.IAM_APP_KEY);
  static activateUser = this.createAPIData(`users/{0}/activate`, 'PUT', Constants.IAM_APP_KEY);

  static getStaffName = this.createAPIData(`staffs/{0}`, 'GET', Constants.IAM_APP_KEY);

  static changePasssword = this.createAPIData(`users/password/change`, 'PUT', Constants.IAM_APP_KEY);
  static resetPassword = this.createAPIData('users/{0}/password/reset', 'POST', Constants.IAM_APP_KEY);

  static login = this.createAPIData('users/login', 'POST', Constants.IAM_APP_KEY, false);
  static logout = this.createAPIData('users/logout', 'POST', Constants.IAM_APP_KEY);
  static checkLogin = this.createAPIData('users/check-login', 'POST', Constants.IAM_APP_KEY);

  static listApps = this.createAPIData('apps', 'GET', Constants.IAM_APP_KEY);
  static getAppRoles = this.createAPIData(`apps/{0}/roles`, 'GET', Constants.IAM_APP_KEY);
  static getAppFeatures = this.createAPIData(`users/{0}/apps/{1}/features`, 'GET', Constants.IAM_APP_KEY);
  static managePermission = this.createAPIData(`users/{0}/apps/{1}/permissions`, 'POST', Constants.IAM_APP_KEY);
  static deleteUserApp = this.createAPIData(`users/{0}/apps/{1}`, 'DELETE', Constants.IAM_APP_KEY);

  // ########################## Reports APIs ####################################
  static getDashboard = this.createAPIData(`dashboard`, 'GET', Constants.REPORTS_APP_KEY);
  static getItemsReport = this.createAPIData(`sales-by-item`, 'GET', Constants.REPORTS_APP_KEY);
  static getItemRangeReport = this.createAPIData(`sales-by-item-range`, 'GET', Constants.REPORTS_APP_KEY);
  static getStockReport = this.createAPIData(`stock`, 'GET', Constants.REPORTS_APP_KEY);
  static getReportSettings = this.createAPIData(`settings`, 'GET', Constants.REPORTS_APP_KEY);
  static updateReportSettings = this.createAPIData(`settings`, 'PUT', Constants.REPORTS_APP_KEY);

  // ########################## Discount APIs ####################################
  static fetchBillDetails = this.createAPIData(`bills/{0}`, 'GET', Constants.DISCOUNT_APP_KEY);
  static createDiscount = this.createAPIData(`bills`, 'POST', Constants.DISCOUNT_APP_KEY);
  static updateDiscount = this.createAPIData(`bills/{0}`, 'PUT', Constants.DISCOUNT_APP_KEY);
  static deleteDiscount = this.createAPIData(`bills/{0}`, 'DELETE', Constants.DISCOUNT_APP_KEY);
  static getDiscountSettings = this.createAPIData(`settings`, 'GET', Constants.DISCOUNT_APP_KEY);
  static updateDiscountSettings = this.createAPIData(`settings`, 'PUT', Constants.DISCOUNT_APP_KEY);

  // ########################## Orders APIs ####################################
  static getMarkListForOrders = this.createAPIData(`marks`, 'GET', Constants.ORDERS_APP_KEY);
  static getPlanList = this.createAPIData(`plan/list/get`, 'GET', Constants.ORDERS_APP_KEY);
  static getPlanDetails = this.createAPIData(`plan/get`, 'GET', Constants.ORDERS_APP_KEY);
  static createPlan = this.createAPIData(`plan/create`, 'POST', Constants.ORDERS_APP_KEY);
  static updatePlan = this.createAPIData(`plan/update`, 'PUT', Constants.ORDERS_APP_KEY);

  static getCategory = this.createAPIData(`category/get`, 'GET', Constants.ORDERS_APP_KEY);
  static getModel = this.createAPIData(`model/get`, 'GET', Constants.ORDERS_APP_KEY);
  static getBrand = this.createAPIData(`brand/get`, 'GET', Constants.ORDERS_APP_KEY);
  static getProductId = this.createAPIData(`product/get`, 'GET', Constants.ORDERS_APP_KEY);
  static getProductName = this.createAPIData(`product/get`, 'GET', Constants.ORDERS_APP_KEY);
  static getVariety = this.createAPIData(`variety/get`, 'GET', Constants.ORDERS_APP_KEY);
  static getSupplier = this.createAPIData(`supplier/get`, 'GET', Constants.ORDERS_APP_KEY);

  static getDirectBookingOrder = this.createAPIData(`order/direct/get`, 'GET', Constants.ORDERS_APP_KEY);
  static getDirectBookingOrderList = this.createAPIData(`order/direct/list/get`, 'GET', Constants.ORDERS_APP_KEY);
  static createDirectBookingOrder = this.createAPIData(`order/direct/create`, 'POST', Constants.ORDERS_APP_KEY);
  static updateDirectBookingOrder = this.createAPIData(`order/direct/update`, 'PUT', Constants.ORDERS_APP_KEY);

  static getBookingOrderList = this.createAPIData(`order/list/get`, 'GET', Constants.ORDERS_APP_KEY);
  static createBookingOrder = this.createAPIData(`order/create`, 'POST', Constants.ORDERS_APP_KEY);
  static updateBookingOrder = this.createAPIData(`order/update`, 'PUT', Constants.ORDERS_APP_KEY);
  static getBookingOrder = this.createAPIData(`order/get`, 'GET', Constants.ORDERS_APP_KEY);
  static calculateSellPrice = this.createAPIData(`order/sell_price/calculate`, 'GET', Constants.ORDERS_APP_KEY);
  static getOrderQuantity = this.createAPIData(`order/quantity/get`, 'GET', Constants.ORDERS_APP_KEY);

  static getOrderReports = this.createAPIData(`reports/orders`, 'GET', Constants.ORDERS_APP_KEY);

  static getActivePlanList = this.createAPIData(`analyze/plans/get`, 'GET', Constants.ORDERS_APP_KEY);
  static getPlanDetailsForAnalysis = this.createAPIData(`analyze/plan/get`, 'GET', Constants.ORDERS_APP_KEY);
  static getPlanOrdersForAnalysis = this.createAPIData(`analyze/plan/orders/get`, 'GET', Constants.ORDERS_APP_KEY);
  static getOrderListByProduct = this.createAPIData(`analyze/plan/orders/get`, 'GET', Constants.ORDERS_APP_KEY);
  static getOrderListBySerial = this.createAPIData(`analyze/plan/orders/get`, 'GET', Constants.ORDERS_APP_KEY);
  static getOrderDetailsForAnalysis = this.createAPIData(`analyze/order/get`, 'GET', Constants.ORDERS_APP_KEY);

  static getEntityColumns = this.createAPIData(`entity/columns/get`, 'GET', Constants.ORDERS_APP_KEY);
  static createNewEntity = this.createAPIData(`entity/create`, 'POST', Constants.ORDERS_APP_KEY);

  static uploadAttachments = this.createAPIData(`order/attachments/upload`, 'POST', Constants.ORDERS_APP_KEY);
  static downloadAttachment = this.createAPIData(`order/attachments/{0}`, 'GET', Constants.ORDERS_APP_KEY);
  static downloadAttachments = this.createAPIData(`order/attachments/download`, 'GET', Constants.ORDERS_APP_KEY);

  static uploadDirectOrderAttachments = this.createAPIData(`order/direct/attachments/upload`, 'POST', Constants.ORDERS_APP_KEY);
  static downloadDirectOrderAttachment = this.createAPIData(`order/direct/attachments/{0}`, 'GET', Constants.ORDERS_APP_KEY);
  static downloadDirectOrderAttachments = this.createAPIData(`order/direct/attachments/download`, 'GET', Constants.ORDERS_APP_KEY);

  // ########################## Approvals APIs ####################################
  static getApprovalModulesList = this.createAPIData(`modules`, 'GET', Constants.APPROVALS_APP_KEY);
  static getApprovalList = this.createAPIData(`modules/{0}`, 'GET', Constants.APPROVALS_APP_KEY);
  static acceptApproval = this.createAPIData(`modules/{0}`, 'PUT', Constants.APPROVALS_APP_KEY);
  static cancelApproval = this.createAPIData(`modules/{0}`, 'DELETE', Constants.APPROVALS_APP_KEY);
}

export default APIData;
