import { AppBar, Backdrop, Badge, Button, Card, Chip, CircularProgress, Dialog, Divider, FormControl, Grid,
  IconButton, InputAdornment, InputLabel, MenuItem, Select, Slide, TextField, Toolbar, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { validateNumber, validateStringForNull } from '../../../../utils/FieldValidations';
import Services from '../../../../utils/Services';
import APIData from '../../../../utils/APIData';
import { blue } from '@mui/material/colors';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { CookieUtils, Utils } from '../../../../utils/UtilFunctions';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { cloneDeep } from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomAutocomplete from '../../../../common/components/CustomAutocomplete';
import { ATTACHMENT_STATUS, MATERIAL_TYPE } from '../../../../utils/EnumDefinitions';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import moment from 'moment';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import TSCamera from '../../../../common/components/TSCamera.js';
import TSGallery from '../../../../common/components/TSGallery.js';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import CachedIcon from '@mui/icons-material/Cached';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});


const OrdersDirectBooking = () => {
  const [isLoading, setIsLoading] = useState(false);
  const purchaserId = CookieUtils.getUserId();
  const [markList, setMarkList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [isUpdateRequest, setIsUpdateRequest] = useState(false);
  const APIToken = {
    GET_DIRECT_BOOKING_ORDER_LIST: 'CODB01',
    GET_DIRECT_BOOKING_ORDER: 'CODB02',
    GET_PRODUCT_ID: 'CODB03',
    CALCULATE_SELL_PRICE: 'CODB04',
    CREATE_DIRECT_BOOKING_ORDER: 'CODB05',
    UPDATE_DIRECT_BOOKING_ORDER: 'CODB06',
    GET_MARK_LIST: 'CODB07',
    UPLOAD_DIRECT_ORDER_ATTACHMENTS: 'CODB08',
    DOWNLOAD_DIRECT_ORDER_ATTACHMENT: 'CODB09',
    DOWNLOAD_DIRECT_ORDER_ATTACHMENTS: 'CODB10'
  };
  const [orderDetails, setOrderDetails] = useState({
    order_id: '',
    order_date: '',
    ac_id: '',
    ac_name: '',
    order_data: [],
    refresh_id: 0
  });
  const [originalValue, setOriginalValue] = useState(orderDetails);
  const [isCameraDialogOpen, setIsCameraDialogOpen] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [cameraParams, setCameraParams] = useState({});

  const readymadeDefaultSubObject = {
    id: '',
    model_id: '',
    model_name: '',
    brand_id: '',
    brand_name: '',
    size_id: '',
    buy_price: '',
    description: '',
    quantity: '',
    attachments: {}
  };
  const readyMadeDefaultObject = {
    id: '',
    cate_id: '',
    cate_name: '',
    prod_id: '',
    prod_name: '',
    item_id: '',
    material_type: '',
    mark_name: '',
    is_expanded: true,
    sub_data: [readymadeDefaultSubObject]
  };

  const silkDefaultSubObject = {
    id: '',
    vrty_id: '',
    buy_price: '',
    sell_price: '',
    description: '',
    quantity: '',
    vrty_name: '',
    attachments: {}
  };
  const silkDefaultObject = {
    id: '',
    prod_id: '',
    prod_name: '',
    item_id: '',
    material_type: '',
    mark_name: '',
    is_expanded: true,
    sub_data: [silkDefaultSubObject]
  };

  const polyesterDefaultSubObject = {
    id: '',
    vrty_id: '',
    buy_price: '',
    no_of_sets: '',
    description: '',
    quantity: '',
    vrty_name: '',
    qty_per_set: 6,
    attachments: {}
  };
  const polyesterDefaultObject = {
    id: '',
    prod_id: '',
    prod_name: '',
    item_id: '',
    material_type: '',
    mark_name: '',
    is_expanded: true,
    sub_data: [polyesterDefaultSubObject]
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if (validateStringForNull(purchaserId)) {
      raiseGetMarkListRequest();
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getMarkListForOrders && apiToken == APIToken.GET_MARK_LIST ) {
      setMarkList(response.data.mark_list);
      raiseGetOrderListRequest();
    } else if (apiData == APIData.getDirectBookingOrderList && apiToken == APIToken.GET_DIRECT_BOOKING_ORDER_LIST ) {
      if (response.data != undefined) {
        const updatedOrderList = [
          ...response.data,
          { order_id: '-- NEW --', order_date: moment().format( 'DD-MM-YYYY') }
        ];
        setOrderList(updatedOrderList);
        const filteredOrder = updatedOrderList.find((obj) => obj.order_id == '-- NEW --');
        orderDetails.order_id = filteredOrder.order_id;
        orderDetails.order_date = filteredOrder.order_date;
        orderDetails.ac_name = '',
        orderDetails.ac_id = '',
        orderDetails.order_data = [];
        notifyOrderDetailsChange();
      } else {
        showSnackBar('error', response.message ?? 'unable to retrieve order list');
      }
      setIsUpdateRequest(false);
    } else if (apiData == APIData.getDirectBookingOrder && apiToken == APIToken.GET_DIRECT_BOOKING_ORDER ) {
      if (response.data != undefined) {
        setIsUpdateRequest(true);
        populateOrderData(response.data);
      } else {
        showSnackBar('error', response.message ?? 'unable to retrieve order details');
      }
    } else if (apiData == APIData.getProductId && apiToken == APIToken.GET_PRODUCT_ID ) {
      setProductName(callbackValues.id, response.data);
    } else if (apiData == APIData.calculateSellPrice && apiToken == APIToken.CALCULATE_SELL_PRICE) {
      if (response.data != undefined) {
        callbackValues.subData.sell_price = response.data.sell_price;
        notifyOrderDetailsChange();
      }
    } else if (apiData == APIData.createDirectBookingOrder && apiToken == APIToken.CREATE_DIRECT_BOOKING_ORDER) {
      showSnackBar('success', response.message ?? 'Order Created successfully');
      raiseGetOrderListRequest();
    } else if (apiData == APIData.updateDirectBookingOrder && apiToken == APIToken.UPDATE_DIRECT_BOOKING_ORDER) {
      if (validateStringForNull(callbackValues.order_id)) {
        raiseGetOrderRequest(callbackValues.order_id);
      }
      showSnackBar('success', response.message ?? 'Order Updated Successfully');
    } else if (apiData == APIData.uploadDirectOrderAttachments && apiToken == APIToken.UPLOAD_DIRECT_ORDER_ATTACHMENTS) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      const attachmentId = response.data.attachments[callbackValues.imageKey];
      if (attachmentId == undefined) {
        subData.attachments[callbackValues.imageKey].attachment_id = undefined;
        subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NEW;
        if (callbackValues.showSnackBar == true) {
          showSnackBar('error', 'Image upload failed. Try again. ' + (response.message != undefined ? `[Error: ${response.message}] ` : ''));
        }
      } else {
        subData.attachments[callbackValues.imageKey].attachment_id = attachmentId;
        subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.DONE;
      }
      notifyOrderDetailsChange();
    } else if (apiData == APIData.downloadDirectOrderAttachment && apiToken == APIToken.DOWNLOAD_DIRECT_ORDER_ATTACHMENT) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      const attachment = response.data.attachment;
      const attachmentId = response.data.attachment_id;
      let showSB = false;
      if (attachmentId == subData.attachments[callbackValues.imageKey].attachment_id) {
        try {
          subData.attachments[callbackValues.imageKey].data = Utils.base64ToFile(attachment, callbackValues.imageKey);
          subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.DONE;
        } catch (err) {
          showSB = true;
          subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
        }
      } else {
        showSB = true;
        subData.attachments[callbackValues.imageKey].data = undefined;
        subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
      }
      if (callbackValues.showSnackBar == true && showSB == true) {
        showSnackBar('error', 'Image download failed. Try again. ' + (response.message != undefined ? `[Error: ${response.message}] ` : ''));
      }
      notifyOrderDetailsChange();
    } else if (apiData == APIData.downloadDirectOrderAttachments && apiToken == APIToken.DOWNLOAD_DIRECT_ORDER_ATTACHMENTS) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      const attachments = response.data;
      let showSB = false;
      callbackValues.imageKeys.map((key) => {
        const aId = subData.attachments[key].attachment_id;
        const arr = attachments.find((obj) => obj.attachment_id == aId);
        if (arr != undefined && arr != null) {
          const attachment = arr.attachment;
          try {
            subData.attachments[key].data = Utils.base64ToFile(attachment, key);
            subData.attachments[key].status = ATTACHMENT_STATUS.DONE;
          } catch (err) {
            showSB = true;
            subData.attachments[key].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
          }
        }
      });
      if (callbackValues.showSnackBar == true && showSB == true) {
        showSnackBar('error', 'Few images failed to download. Try again. ' + (response.message != undefined ? `[Error: ${response.message}] ` : ''));
      }
      notifyOrderDetailsChange();
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getMarkListForOrders && apiToken == APIToken.GET_MARK_LIST ) {
      defaultMsg = 'Failed to retrieve mark list';
    } else if (apiData == APIData.getDirectBookingOrderList && apiToken == APIToken.GET_DIRECT_BOOKING_ORDER_LIST) {
      defaultMsg = 'Failed to retrieve Order List';
    } else if (apiData == APIData.getDirectBookingOrder && apiToken == APIToken.GET_DIRECT_BOOKING_ORDER ) {
      defaultMsg = 'Failed to retrieve Order';
    } else if (apiData == APIData.getProductId && apiToken == APIToken.GET_PRODUCT_ID ) {
      defaultMsg = 'Failed to get product details';
    } else if (apiData == APIData.calculateSellPrice && apiToken == APIToken.CALCULATE_SELL_PRICE) {
      defaultMsg = 'Failed to calulate sell price';
    } else if (apiData == APIData.createDirectBookingOrder && apiToken == APIToken.CREATE_DIRECT_BOOKING_ORDER) {
      defaultMsg = 'Failed to Create Order';
    } else if (apiData == APIData.updateDirectBookingOrder && apiToken == APIToken.UPDATE_DIRECT_BOOKING_ORDER) {
      defaultMsg = 'Failed to Update Order';
    } else if (apiData == APIData.uploadDirectOrderAttachments && apiToken == APIToken.UPLOAD_DIRECT_ORDER_ATTACHMENTS) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      subData.attachments[callbackValues.imageKey].attachment_id = undefined;
      subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NEW;
      if (callbackValues.showSnackBar == true) {
        showSnackBar('error', 'Image upload failed. Try again. ' + (err.message != undefined ? `[Error: ${err.message}] ` : ''));
      }
      notifyOrderDetailsChange();
      return;
    } else if (apiData == APIData.downloadDirectOrderAttachment && apiToken == APIToken.DOWNLOAD_DIRECT_ORDER_ATTACHMENT) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      subData.attachments[callbackValues.imageKey].data = undefined;
      subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
      if (callbackValues.showSnackBar == true) {
        showSnackBar('error', 'Image download failed. Try again. ' + (err.message != undefined ? `[Error: ${err.message}] ` : ''));
      }
      notifyOrderDetailsChange();
      return;
    } else if (apiData == APIData.downloadDirectOrderAttachments && apiToken == APIToken.DOWNLOAD_DIRECT_ORDER_ATTACHMENTS) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      callbackValues.imageKeys.map((key) => {
        subData.attachments[key].data = undefined;
        subData.attachments[key].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
      });
      if (callbackValues.showSnackBar == true) {
        showSnackBar('error', 'Few images failed to download. Try again. ' + (err.message != undefined ? `[Error: ${err.message}] ` : ''));
      }
      notifyOrderDetailsChange();
      return;
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetMarkListRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getMarkListForOrders },
      APIToken.GET_MARK_LIST, processSuccess, processError);
  };

  const raiseGetOrderListRequest = () => {
    setIsLoading(true);
    const params = 'purchaser_id=' + purchaserId;
    Services.sendBackendRequest({ apiData: APIData.getDirectBookingOrderList, params: params },
      APIToken.GET_DIRECT_BOOKING_ORDER_LIST, processSuccess, processError );
  };

  const raiseGetOrderRequest = (orderId) => {
    setIsLoading(true);
    const params = 'order_id=' + orderId;
    Services.sendBackendRequest({ apiData: APIData.getDirectBookingOrder, params: params },
      APIToken.GET_DIRECT_BOOKING_ORDER, processSuccess, processError );
  };


  const raiseGetProductDetailsRequest = (id, productId) => {
    setIsLoading(true);
    const params = 'prod_id=' + productId;
    Services.sendBackendRequest({ apiData: APIData.getProductId, params: params },
      APIToken.GET_PRODUCT_ID, processSuccess, processError, { id: id });
  };

  const raiseCalulateSellPriceRequest = (subData, prodId) => {
    if (!validateStringForNull(subData.buy_price) || !validateStringForNull(prodId)) {
      return;
    }
    setIsLoading(true);
    const params = 'prod_id=' + prodId + '&buy_price=' + subData.buy_price;
    Services.sendBackendRequest({ apiData: APIData.calculateSellPrice, params: params },
      APIToken.CALCULATE_SELL_PRICE, processSuccess, processError, { subData: subData });
  };

  const notifyOrderDetailsChange = () => {
    setOrderDetails({ ...orderDetails, refresh_id: orderDetails.refresh_id + 1 });
  };


  const handleProductDetailsSearch = (id, prodId) => {
    if (validateStringForNull(prodId)) {
      raiseGetProductDetailsRequest(id, prodId);
    }
  };

  const populateOrderAttachments = (attachmentIds, dataId, subdataId) => {
    const attachments = {};
    if (attachmentIds == undefined) return attachments;
    attachmentIds.map((id, i) => {
      const key = dataId.toString() + subdataId.toString() + moment().valueOf().toString() + i + '.png';
      const obj = {
        attachment_id: id,
        status: ATTACHMENT_STATUS.NOT_DOWNLOADED
      };
      attachments[key]= obj;
    });
    return attachments;
  };

  const populateOrderData = (data) => {
    const obj = {};
    data.order_data.forEach((order) => {
      const materialType = MATERIAL_TYPE[order.material_type];
      if (materialType == 3) {
        const key = `${order.item_id}_${order.cate_id}_${order.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            cate_name: order.cate_name,
            cate_id: order.cate_id,
            prod_id: order.prod_id,
            prod_name: order.prod_name,
            item_id: order.item_id,
            material_type: materialType,
            mark_name: order.mark_name,
            is_expanded: true,
            sub_data: []
          };
        }
        const readymadeObj = {
          id: obj[key].sub_data.length + 1,
          model_name: order.model_name,
          brand_name: order.brand_name,
          model_id: order.model_id,
          brand_id: order.brand_id,
          buy_price: order.buy_price,
          size_id: order.size_id,
          quantity: order.quantity,
          description: order.description,
          serial: order.serial
        };
        readymadeObj.attachments = populateOrderAttachments(order.attachment_id, obj[key].id, readymadeObj.id);
        obj[key].sub_data.push(readymadeObj);
      } else if (materialType == 2) {
        const key = `${order.item_id}_${order.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            prod_id: order.prod_id,
            prod_name: order.prod_name,
            is_expanded: true,
            item_id: order.item_id,
            material_type: materialType,
            mark_name: order.mark_name,
            sub_data: []
          };
        }
        const silkObj = {
          id: obj[key].sub_data.length + 1,
          buy_price: order.buy_price,
          sell_price: order.sell_price,
          quantity: order.quantity,
          vrty_id: order.vrty_id,
          description: order.description,
          vrty_name: order.vrty_name,
          serial: order.serial
        };
        silkObj.attachments = populateOrderAttachments(order.attachment_id, obj[key].id, silkObj.id);
        obj[key].sub_data.push(silkObj);
      } else {
        const key = `${order.item_id}_${order.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            prod_id: order.prod_id,
            prod_name: order.prod_name,
            is_expanded: true,
            item_id: order.item_id,
            material_type: materialType,
            mark_name: order.mark_name,
            sub_data: []
          };
        }
        const polyesterObj = {
          id: obj[key].sub_data.length + 1,
          buy_price: order.buy_price,
          quantity: order.quantity,
          description: order.description,
          vrty_id: validateStringForNull(order.vrty_id) ? order.vrty_id : '',
          vrty_name: validateStringForNull(order.vrty_name) ? order.vrty_name : '',
          qty_per_set: 6,
          no_of_sets: validateStringForNull(order.no_of_sets) ? order.no_of_sets : '',
          serial: order.serial
        };
        polyesterObj.attachments = populateOrderAttachments(order.attachment_id, obj[key].id, polyesterObj.id);
        obj[key].sub_data.push(polyesterObj);
      }
    });
    const filteredData = Object.values(obj);
    const filteredOrderObj = {
      order_id: data.order_id,
      order_date: data.order_date,
      ac_id: validateStringForNull(data.ac_id) ? data.ac_id: '',
      ac_name: validateStringForNull(data.ac_name) ? data.ac_name: '',
      order_data: filteredData,
      refresh_id: 0
    };
    setOrderDetails(filteredOrderObj);
    setOriginalValue(cloneDeep(filteredOrderObj));
  };

  const validateAndGetAttachmentsForCreateUpdate = (attachments) => {
    const imgs = [];
    if (attachments == undefined) return imgs;
    Object.keys(attachments).map((key) => {
      if (!validateStringForNull(attachments[key].attachment_id)) {
        let err = 'Please wait until the attachments finish uploading.';
        err+= ' For rows with a warning sign, click the upload button again for each image to retry uploading';
        throw new Error(err);
      }
      imgs.push(attachments[key].attachment_id);
    });
    return imgs;
  };

  const computeBuyQuantityForPolyester = (subData) => {
    if (validateStringForNull(subData.no_of_sets) && validateStringForNull(subData.qty_per_set)) {
      const noOfSets = parseInt(subData.no_of_sets);
      const qtyPerSet = parseInt(subData.qty_per_set);
      subData.quantity = (noOfSets * qtyPerSet).toString();
      notifyOrderDetailsChange();
    }
  };

  const handleOrderChange = (event) => {
    const { name, value } = event.target;
    const filteredOrder = orderList.find((order) => order.order_id === value);
    setOrderDetails({ ...orderDetails, [name]: value, ac_name: '', ac_id: '', order_data: [] });
    if (filteredOrder.order_id != '-- NEW --') {
      raiseGetOrderRequest(value);
    } else {
      setIsUpdateRequest(false);
    }
  };

  const setProductName = (id, response) => {
    const dataObj= orderDetails.order_data.find((item) => item.id === id);
    if (dataObj != undefined && response.length > 0) {
      const { prod_id: prodId, prod_name: prodName } = response[0];
      dataObj.prod_id = prodId;
      dataObj.prod_name = prodName;
      notifyOrderDetailsChange();
    }
  };

  const handleAddMarkClick = (mark) => {
    const material = MATERIAL_TYPE[mark.material_type];
    const lastIdData = orderDetails.order_data.length > 0 ? orderDetails.order_data[orderDetails.order_data.length - 1].id : 0;
    let newSubObj = {};
    let newObj = {};
    if (material == 3) {
      newObj = readyMadeDefaultObject;
      newSubObj = readymadeDefaultSubObject;
    } else if (material == 2) {
      newObj = silkDefaultObject;
      newSubObj = silkDefaultSubObject;
    } else if (material == 1) {
      newObj = polyesterDefaultObject;
      newSubObj = polyesterDefaultSubObject;
    }
    newSubObj.id = 1;
    newObj.id = lastIdData + 1;
    newObj.sub_data = [newSubObj];
    newObj.item_id = mark.item_id;
    newObj.material_type = material;
    newObj.mark_name = mark.mark_name;
    orderDetails.order_data.push(newObj);
    notifyOrderDetailsChange();
  };

  const handleResetClick = () => {
    const cloned = cloneDeep(originalValue);
    setOrderDetails(cloned);
  };

  const handleSupplierNameChange = (value, idField, nameField) => {
    if (validateStringForNull(value) && validateStringForNull(value.ac_id)) {
      setOrderDetails({ ...orderDetails,
        ac_id: value.ac_id,
        ac_name: value.ac_name
      });
    } else {
      setOrderDetails({ ...orderDetails,
        ac_id: '',
        ac_name: ''
      });
    }
  };

  const handleAutoCompleteChange = (value, data, idField, nameField) => {
    data[idField] = value != null ? value[idField] : '';
    data[nameField] = value != null ? value[nameField] : '';
    if (idField === 'cate_id') {
      data.prod_id = '';
      data.prod_name = '';
    }
    notifyOrderDetailsChange();
  };

  const handleAddSubDataClick = (data) => {
    const lastId = data.sub_data[data.sub_data.length - 1].id;
    let newSubObj = {};
    if (data.material_type == 3) {
      newSubObj = readymadeDefaultSubObject;
    } else if (data.material_type == 2) {
      newSubObj = silkDefaultSubObject;
    } else if (data.material_type == 1) {
      newSubObj = polyesterDefaultSubObject;
    }
    newSubObj.id = lastId + 1;
    data.sub_data.push(newSubObj);
    notifyOrderDetailsChange();
  };

  const handleRemoveSubDataClick = (data, subDataId) => {
    data.sub_data = data.sub_data.filter((subItem) => subItem.id !== subDataId);
    notifyOrderDetailsChange();
  };

  const handleRemoveDataClick = (id) => {
    orderDetails.order_data = orderDetails.order_data.filter((item) => item.id !== id);
    notifyOrderDetailsChange();
  };

  const handleProductIdChange = (event, data) => {
    const { name, value } = event.target;
    data[name] = value.toUpperCase();
    if (!validateStringForNull(value)) {
      data.prod_name = '';
    }
    notifyOrderDetailsChange();
  };

  const handleSubDataTextChange = (event, subData) => {
    const { name, value } = event.target;
    subData[name] = value.toUpperCase();
    notifyOrderDetailsChange();
  };

  const handleSubDataNumberChange = (event, subData) => {
    const { name, value } = event.target;
    if (validateStringForNull(value) && !validateNumber(value) ) {
      return;
    }
    subData[name] = value == '' ? '' : Number(value);
    notifyOrderDetailsChange();
  };


  const getProductNameSearchParams = (cateId) => {
    if (validateStringForNull(cateId)) {
      return 'cate_id='+ cateId;
    }
    return '';
  };

  const handleAddPhotoClick = (dataId, subdataId) => {
    const sdata = findSubData(dataId, subdataId);
    if (sdata != null && sdata != undefined) {
      setCameraParams({ dataId: dataId, subdataId: subdataId, images: sdata.attachments });
      setIsCameraDialogOpen(true);
    }
  };

  const findSubData = (dataId, subdataId) => {
    const data = orderDetails.order_data.find((obj) => obj.id == dataId);
    if (data != null && data != undefined) {
      const subdata = data.sub_data.find((obj) => obj.id == subdataId);
      return subdata;
    }
    return undefined;
  };

  const handleGalleryClick = (dataId, subdataId) => {
    const sdata = findSubData(dataId, subdataId);
    if (sdata != null && sdata != undefined) {
      setCameraParams({ dataId: dataId, subdataId: subdataId, images: sdata.attachments });
      setIsGalleryOpen(true);
      // const ids= [];
      Object.keys(sdata.attachments).map((key) => {
        if (sdata.attachments[key].data == undefined) {
          // ids.push(key);
          handleDownloadPic(dataId, subdataId, key, false);
        }
      });
      // if (ids.length > 0) {
      //   try {
      //     raiseDownloadImages(dataId, subdataId, ids);
      //   } catch (err) {
      //     console.log(err);
      //   }
      // }
    }
  };

  const handleDownloadPic = (dataId, subdataId, imgKey, showSnackBar) => {
    if (imgKey != undefined) {
      try {
        raiseDownloadImage(dataId, subdataId, imgKey, showSnackBar);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleImageCapture = (img) => {
    const sdata = findSubData(cameraParams.dataId, cameraParams.subdataId);
    if (sdata != null && sdata != undefined) {
      const key = cameraParams.dataId.toString() + cameraParams.subdataId.toString() + moment().valueOf().toString() + '.png';
      const file = new File([img], key, { type: 'image/png' });
      sdata.attachments[key] = {};
      sdata.attachments[key].data = file;
      notifyOrderDetailsChange();
      try {
        raiseUploadImage(cameraParams.dataId, cameraParams.subdataId, file, false);
      } catch (err) {
        console.log(err);
      }
    }
    setIsCameraDialogOpen(false);
  };

  const handleImageDelete = (dataId, subdataId, key) => {
    const sdata = findSubData(dataId, subdataId);
    if (sdata != null && sdata != undefined) {
      delete sdata.attachments[key];
      notifyOrderDetailsChange();
    }
  };

  const getIsAttachmentsUploadPending = (imgs) => {
    let uploadPending = false;
    if (imgs == undefined) return uploadPending;
    Object.keys(imgs).map((key) => {
      if (!validateStringForNull(imgs[key].attachment_id)) {
        uploadPending = true;
      }
    });
    return uploadPending;
  };

  const handleAddDataClick = (order) => {
    let newSubObj;

    if (order.material_type == 1) {
      newSubObj = polyesterDefaultSubObject;
    } else if (order.material_type == 2) {
      newSubObj = silkDefaultSubObject;
    } else {
      newSubObj = readymadeDefaultSubObject;
    }
    newSubObj.id = 1;
    let newObj;

    if (order.material_type == 3) {
      newObj = {
        ...readyMadeDefaultObject,
        item_id: order.item_id,
        material_type: order.material_type,
        mark_name: order.mark_name,
        prod_id: order.prod_id,
        prod_name: order.prod_name,
        cate_id: order.cate_id,
        cate_name: order.cate_name,
        id: orderDetails.order_data.length > 0 ? orderDetails.order_data[orderDetails.order_data.length - 1].id + 1 : 1,
        sub_data: [newSubObj]
      };
    } else if (order.material_type == 2) {
      newObj = {
        ...silkDefaultObject,
        item_id: order.item_id,
        material_type: order.material_type,
        mark_name: order.mark_name,
        prod_id: order.prod_id,
        prod_name: order.prod_name,
        id: orderDetails.order_data.length > 0 ? orderDetails.order_data[orderDetails.order_data.length - 1].id + 1 : 1,
        sub_data: [newSubObj]
      };
    } else {
      newObj = {
        ...polyesterDefaultObject,
        item_id: order.item_id,
        material_type: order.material_type,
        mark_name: order.mark_name,
        prod_id: order.prod_id,
        prod_name: order.prod_name,
        id: orderDetails.order_data.length > 0 ? orderDetails.order_data[orderDetails.order_data.length - 1].id + 1 : 1,
        sub_data: [newSubObj]
      };
    }
    orderDetails.order_data.push(newObj);
    notifyOrderDetailsChange();
  };

  const validateOrderDataArray = (params) => {
    const validatedOrders = params.order_data.map((order, index) => {
      if (order.material_type == 1) {
        return validatePolyesterPlanParams(order);
      } else if (order.material_type == 2) {
        return validateSilkPlanParams(order);
      } else if (order.material_type == 3) {
        return validateReadyMadePlanParams(order);
      }
    });
    return validatedOrders;
  };

  const handleCreateOrUpdateOrderRequest = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(orderDetails);
      const filteredParamsArray = validateOrderDataArray(params).flat();
      const filteredParams = {
        ...validateOrderDetails(params),
        order_data: filteredParamsArray
      };
      raiseCreateOrUpdateOrderRequest(filteredParams);
    } catch (err) {
      showSnackBar('error', err.message ?? (isUpdateRequest ? 'Failed to Update Order' : 'Failed to Create Order'));
      setIsLoading(false);
    }
  };

  const validateOrderDetails = (params) => {
    const finalParams = {};
    if (isUpdateRequest) {
      if (!validateStringForNull(params.order_id)) {
        throw new Error('Select valid Order Id');
      }
      finalParams.order_id = params.order_id;
    }
    if (!validateStringForNull(params.order_date)) {
      throw new Error('Select valid Order Date');
    }
    if (!validateStringForNull(params.ac_id)) {
      throw new Error('Select valid Supplier Name');
    }

    finalParams.order_date = params.order_date;
    finalParams.ac_id = params.ac_id;

    return finalParams;
  };

  const validateSilkPlanParams = (order) => {
    if (!validateStringForNull(order.prod_id)) {
      throw new Error('Select valid Product');
    }
    if (!validateStringForNull(order.item_id)) {
      throw new Error('Select valid Mark');
    }
    const orderDataArray = [];
    order.sub_data.forEach((subData) => {
      if (!validateStringForNull(subData.buy_price)) {
        throw new Error('Provide valid Buy Price');
      }
      if (!validateStringForNull(subData.sell_price)) {
        throw new Error('Provide valid Sell Price');
      }
      if (!validateStringForNull(subData.quantity)) {
        throw new Error('Provide valid Buy Quantity');
      }
      const imgs = validateAndGetAttachmentsForCreateUpdate(subData.attachments);
      const orderObj = {
        prod_id: order.prod_id,
        item_id: order.item_id,
        buy_price: parseFloat(subData.buy_price),
        sell_price: parseFloat(subData.sell_price),
        quantity: parseInt(subData.quantity)
      };
      if (imgs.length > 0) {
        orderObj.attachments = imgs;
      }
      if (validateStringForNull(subData.description)) {
        orderObj.description = subData.description;
      }
      if (validateStringForNull(subData.vrty_id)) {
        orderObj.vrty_id = subData.vrty_id;
      }
      orderDataArray.push(orderObj);
    });
    return orderDataArray;
  };

  const validateReadyMadePlanParams = (order) => {
    if (!validateStringForNull(order.cate_id)) {
      throw new Error('Select valid Category');
    }
    if (!validateStringForNull(order.prod_id)) {
      throw new Error('Select valid Product');
    }
    if (!validateStringForNull(order.item_id)) {
      throw new Error('Select valid Mark');
    }
    const orderDataArray = [];
    order.sub_data.forEach((subData) => {
      if (!validateStringForNull(subData.buy_price)) {
        throw new Error('Provide valid Buy Price');
      }
      if (!validateStringForNull(subData.quantity)) {
        throw new Error('Provide valid Buy Quantity');
      }
      const imgs = validateAndGetAttachmentsForCreateUpdate(subData.attachments);
      const orderObj = {
        cate_id: order.cate_id,
        prod_id: order.prod_id,
        item_id: order.item_id,
        buy_price: parseFloat(subData.buy_price),
        quantity: parseInt(subData.quantity)
      };
      if (imgs.length > 0) {
        orderObj.attachments = imgs;
      }
      if (validateStringForNull(subData.description)) {
        orderObj.description = subData.description;
      }
      if (validateStringForNull(subData.model_id)) {
        orderObj.model_id = subData.model_id;
      }
      if (validateStringForNull(subData.brand_id)) {
        orderObj.brand_id = subData.brand_id;
      }
      if (validateStringForNull(subData.size_id)) {
        orderObj.size_id = subData.size_id;
      }

      orderDataArray.push(orderObj);
    });
    return orderDataArray;
  };

  const validatePolyesterPlanParams = (order) => {
    if (!validateStringForNull(order.item_id)) {
      throw new Error('Select valid Mark');
    }
    if (!validateStringForNull(order.prod_id)) {
      throw new Error('Select valid Product');
    }

    const orderDataArray = [];

    order.sub_data.forEach((subData) => {
      if (!validateStringForNull(subData.buy_price)) {
        throw new Error('Provide valid Buy Price');
      }
      if (!validateStringForNull(subData.quantity)) {
        throw new Error('Provide valid Buy Quantity');
      }
      const imgs = validateAndGetAttachmentsForCreateUpdate(subData.attachments);
      const orderObj = {
        prod_id: order.prod_id,
        item_id: order.item_id,
        buy_price: parseFloat(subData.buy_price),
        quantity: parseInt(subData.quantity)
      };
      if (imgs.length > 0) {
        orderObj.attachments = imgs;
      }
      if (validateStringForNull(subData.vrty_id)) {
        orderObj.vrty_id = subData.vrty_id;
      }
      if (validateStringForNull(subData.description)) {
        orderObj.description = subData.description;
      }
      orderDataArray.push(orderObj);
    });

    return orderDataArray;
  };

  const raiseUploadImage = (dataId, subdataId, img, showSnackBar) => {
    const data = orderDetails.order_data.find((obj) => obj.id == dataId);
    const subdata = data.sub_data.find((obj) => obj.id == subdataId);
    subdata.attachments[img.name].status = ATTACHMENT_STATUS.UPLOADING;
    notifyOrderDetailsChange();
    const formData = new FormData();
    formData.append('item_id', data.item_id);
    formData.append('attachments', img);
    Services.sendMultipartRequest({ apiData: APIData.uploadDirectOrderAttachments, params: formData },
      APIToken.UPLOAD_DIRECT_ORDER_ATTACHMENTS, processSuccess, processError,
      { dataId: dataId, subdataId: subdataId, imageKey: img.name, showSnackBar: showSnackBar });
  };

  const raiseDownloadImage = (dataId, subdataId, imgKey, showSnackBar) => {
    const data = orderDetails.order_data.find((obj) => obj.id == dataId);
    const subdata = data.sub_data.find((obj) => obj.id == subdataId);
    subdata.attachments[imgKey].status = ATTACHMENT_STATUS.DOWNLOADING;
    notifyOrderDetailsChange();
    const params = 'order_id=' + orderDetails.order_id + '&serial=' + subdata.serial;
    Services.sendBackendRequest({ apiData: APIData.downloadDirectOrderAttachment,
      uriValues: [subdata.attachments[imgKey].attachment_id], params: params },
    APIToken.DOWNLOAD_DIRECT_ORDER_ATTACHMENT, processSuccess, processError,
    { dataId: dataId, subdataId: subdataId, imageKey: imgKey, showSnackBar: showSnackBar });
  };

  // const raiseDownloadImages = (dataId, subdataId, imgKeys, showSnackBar) => {
  //   const data = orderDetails.order_data.find((obj) => obj.id == dataId);
  //   const subdata = data.sub_data.find((obj) => obj.id == subdataId);
  //   const attachmentIds = [];
  //   imgKeys.map((key) => {
  //     subdata.attachments[key].status = ATTACHMENT_STATUS.DOWNLOADING;
  //     attachmentIds.push(subdata.attachments[key].attachment_id);
  //   });
  //   notifyOrderDetailsChange();
  //   const params = 'attachment_ids=' + attachmentIds.join(',') + '&order_id=' + orderDetails.order_id + '&serial=' + subdata.serial;
  //   Services.sendBackendRequest({ apiData: APIData.downloadDirectOrderAttachments, params: params },
  //     APIToken.DOWNLOAD_DIRECT_ORDER_ATTACHMENTS, processSuccess, processError,
  //  { dataId: dataId, subdataId: subdataId, imageKeys: imgKeys, showSnackBar: showSnackBar });
  // };

  const raiseCreateOrUpdateOrderRequest = (finalParams) => {
    let apiData = undefined;
    let apiToken = undefined;
    if (isUpdateRequest) {
      apiData = APIData.updateDirectBookingOrder;
      apiToken = APIToken.UPDATE_DIRECT_BOOKING_ORDER;
    } else {
      apiData = APIData.createDirectBookingOrder;
      apiToken = APIToken.CREATE_DIRECT_BOOKING_ORDER;
    }
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: apiData, params: finalParams },
      apiToken, processSuccess, processError, { order_id: orderDetails.order_id } );
  };

  const handleShowPlanDetails = (data) => {
    data.is_expanded = !data.is_expanded;
    notifyOrderDetailsChange();
  };

  const getVarietyNameSearchParams = (prodId) => {
    if (validateStringForNull(prodId)) {
      return 'prod_id='+prodId;
    }
    return '';
  };

  const getMaterialContent = (order) => {
    switch (order.material_type) {
    case 1:
      return getPolyesterContent(order);
    case 2:
      return getSilkContent(order);
    case 3:
      return getReadyMadeContent(order);
    default:
      return 'Invalid Material type';
    }
  };

  const getReadyMadeContent = (order) => {
    return (
      <Grid item xs={12} key={order.id}>
        <Card elevation={12}>
          <Grid key={order.id} container>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
              sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
              <Grid item xs={2.8} sm={1.2} md={1.1}>
                <TextField
                  id="mark_name"
                  name="mark_name"
                  autoComplete ='off'
                  variant='outlined'
                  value={order.mark_name}
                  size='small'
                  fullWidth
                  sx={{ backgroundColor: '#205295', borderRadius: '7px' }}
                  InputProps={{
                    style: { color: 'white' },
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={9.2} sm={4.4} md={4.5}>
                <CustomAutocomplete
                  label={'Category Name'}
                  apiInfo={{
                    searchURI: APIData.getCategory,
                    searchField: 'cate_name',
                    idField: 'cate_id'
                  }}
                  defaultValue={{
                    cate_id: order.cate_id,
                    cate_name: order.cate_name
                  }}
                  onSelectionChange={(event, value) =>
                    handleAutoCompleteChange(value, order, 'cate_id', 'cate_name')}
                />
              </Grid>
              <Grid item xs={7.8} sm={4.4} md={4.5}>
                <CustomAutocomplete
                  label={'Product Name'}
                  apiInfo={{
                    searchURI: APIData.getProductName,
                    searchField: 'prod_name',
                    idField: 'prod_id',
                    searchParams: getProductNameSearchParams(order.cate_id)
                  }}
                  defaultValue={{
                    prod_id: order.prod_id,
                    prod_name: order.prod_name
                  }}
                  onSelectionChange={(event, value) =>
                    handleAutoCompleteChange(value, order, 'prod_id', 'prod_name')}
                />
              </Grid>
              <Grid item xs={4.2} sm={2} md={1.9} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                <IconButton onClick={() => handleAddDataClick(order)} color='primary' size='small'>
                  <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                </IconButton>
                <IconButton
                  onClick={() => handleRemoveDataClick(order.id)} color='error' size='small' sx={{ p: 1 }}>
                  <DeleteIcon/>
                </IconButton>
                <IconButton color='primary' onClick={() => handleShowPlanDetails(order)}>
                  {order.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                </IconButton>
              </Grid>
            </Grid>
            {order.is_expanded == true ? order.sub_data.map((orderSubData, j) => (
              <Grid container item xs={12} key={orderSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                <Grid item container xs={12} lg={5} rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12} sm={5} lg={6}>
                    <CustomAutocomplete
                      label={'Model Name'}
                      apiInfo={{
                        searchURI: APIData.getModel,
                        searchField: 'model_name',
                        idField: 'model_id'
                      }}
                      defaultValue={{
                        model_id: orderSubData.model_id,
                        model_name: orderSubData.model_name
                      }}
                      onSelectionChange={(event, value) =>
                        handleAutoCompleteChange(value, orderSubData, 'model_id', 'model_name')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5} lg={6}>
                    <CustomAutocomplete
                      label={'Brand Name'}
                      apiInfo={{
                        searchURI: APIData.getBrand,
                        searchField: 'brand_name',
                        idField: 'brand_id'
                      }}
                      defaultValue={{
                        brand_id: orderSubData.brand_id,
                        brand_name: orderSubData.brand_name
                      }}
                      onSelectionChange={(event, value) =>
                        handleAutoCompleteChange(value, orderSubData, 'brand_id', 'brand_name')}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={12} lg={7} rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={4} sm={1.5}>
                    <TextField
                      id="size"
                      label="Size"
                      name="size_id"
                      autoComplete ='off'
                      variant='standard'
                      value={orderSubData.size_id}
                      onChange={(event) => handleSubDataTextChange(event, orderSubData)}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={8} sm={3.5} md={3}>
                    <TextField
                      id="description"
                      label="Description"
                      name="description"
                      autoComplete ='off'
                      variant='standard'
                      value={orderSubData.description}
                      onChange={(event) => handleSubDataTextChange(event, orderSubData)}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4} sm={2.5} md={2.5}>
                    <TextField
                      id="buy_price"
                      label="Buy Price"
                      name="buy_price"
                      autoComplete ='off'
                      variant='standard'
                      value={orderSubData.buy_price}
                      onChange={(event) => handleSubDataNumberChange(event, orderSubData)}
                      size='small'
                      fullWidth
                      type='number'
                    />
                  </Grid>
                  <Grid item xs={3} sm={2.5} md={2}>
                    <TextField
                      id="buy_quantity"
                      label="Buy Quantity"
                      name="quantity"
                      autoComplete ='off'
                      variant='standard'
                      value={orderSubData.quantity}
                      onChange={(event) => handleSubDataNumberChange(event, orderSubData)}
                      size='small'
                      fullWidth
                      type='phone'
                    />
                  </Grid>
                  <Grid item xs={5} sm={2} md={3} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                    {j === order.sub_data.length - 1 && (
                      <IconButton onClick={() => handleAddSubDataClick(order)} color='primary' size='small'>
                        <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                      </IconButton>
                    )}
                    {order.sub_data.length > 1 ? (
                      <IconButton onClick={() => handleRemoveSubDataClick(order, orderSubData.id)} color='error' size='small'>
                        <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                      </IconButton>
                    ) : ''}
                    <Badge badgeContent={orderSubData.attachments != undefined ? Object.keys(orderSubData.attachments).length : 0}
                      showZero color="success" overlap="circular">
                      <IconButton onClick={() => handleGalleryClick(order.id, orderSubData.id)} color='success' size='small'>
                        <AddAPhotoIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                      </IconButton>
                    </Badge>
                    {getIsAttachmentsUploadPending(orderSubData.attachments) ? (
                      <IconButton color='warning' size='small'>
                        <WarningIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                      </IconButton>
                    ) : ''}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )) : ''}
          </Grid>
        </Card>
      </Grid>
    );
  };

  const getSilkContent = (order) => {
    return (
      <Grid item xs={12} key={order.id}>
        <Card elevation={12}>
          <Grid key={order.id} container>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
              sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
              <Grid item xs={2.8} sm={1.2} md={1.1}>
                <TextField
                  id="mark_name"
                  name="mark_name"
                  autoComplete ='off'
                  variant='outlined'
                  value={order.mark_name}
                  size='small'
                  fullWidth
                  sx={{ backgroundColor: '#205295', borderRadius: '7px' }}
                  InputProps={{
                    style: { color: 'white' },
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={9.2} sm={4.4} md={4.5}>
                <TextField
                  id="prod_id"
                  label="Product Id"
                  name="prod_id"
                  autoComplete ='off'
                  sx={{ backgroundColor: 'white' }}
                  value={order.prod_id}
                  onChange={(event) => handleProductIdChange(event, order)}
                  size='small'
                  fullWidth
                  onBlur={() => handleProductDetailsSearch(order.id, order.prod_id)}
                />
              </Grid>
              <Grid item xs={7.8} sm={4.4} md={4.5}>
                <TextField
                  id="prod_name"
                  label="Product Name"
                  name="prod_id"
                  autoComplete ='off'
                  sx={{ backgroundColor: 'white' }}
                  value={order.prod_name}
                  size='small'
                  fullWidth
                  disabled={true}
                />
              </Grid>
              <Grid item xs={4.2} sm={2} md={1.9} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                <IconButton onClick={() => handleAddDataClick(order)} color='primary' size='small'>
                  <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                </IconButton>
                <IconButton
                  onClick={() => handleRemoveDataClick(order.id)} color='error' size='small' sx={{ p: 1 }}>
                  <DeleteIcon/>
                </IconButton>
                <IconButton color='primary' onClick={() => handleShowPlanDetails(order)} >
                  {order.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                </IconButton>
              </Grid>
            </Grid>
            {order.is_expanded == true ? order.sub_data.map((orderSubData, j) => (
              <Grid container item xs={12} key={orderSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                <Grid item xs={6} sm={2.5}>
                  <CustomAutocomplete
                    label={'Variety'}
                    apiInfo={{
                      searchURI: APIData.getVariety,
                      searchField: 'vrty_name',
                      idField: 'vrty_id',
                      searchParams: getVarietyNameSearchParams(order.prod_id)
                    }}
                    defaultValue={{
                      vrty_id: orderSubData.vrty_id,
                      vrty_name: orderSubData.vrty_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleAutoCompleteChange(value, orderSubData, 'vrty_id', 'vrty_name')}
                  />
                </Grid>
                <Grid item xs={6} sm={2.5}>
                  <TextField
                    id="description"
                    label="Description"
                    name="description"
                    autoComplete ='off'
                    variant='standard'
                    value={orderSubData.description}
                    onChange={(event) => handleSubDataTextChange(event, orderSubData)}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={1.9}>
                  <TextField
                    id="buy_price"
                    label="Buy Price"
                    name="buy_price"
                    autoComplete ='off'
                    variant='standard'
                    value={orderSubData.buy_price}
                    onChange={(event) => handleSubDataNumberChange(event, orderSubData)}
                    size='small'
                    fullWidth
                    type='number'
                    onBlur={(event, value) => raiseCalulateSellPriceRequest(orderSubData, order.prod_id)}
                  />
                </Grid>
                <Grid item xs={6} sm={1.9}>
                  <TextField
                    id="sell_price"
                    label="Sell Price"
                    name="sell_price"
                    autoComplete ='off'
                    variant='standard'
                    value={orderSubData.sell_price}
                    onChange={(event) => handleSubDataNumberChange(event, orderSubData)}
                    size='small'
                    type='number'
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => raiseCalulateSellPriceRequest(orderSubData, order.prod_id)}
                            onMouseDown={(e) => e.preventDefault()}
                            onMouseUp={(e) => e.preventDefault()}
                            edge="end"
                          >
                            <CachedIcon/>
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={1.2}>
                  <TextField
                    id="buy_quantity"
                    label="Buy Qty"
                    name="quantity"
                    variant='standard'
                    autoComplete ='off'
                    value={orderSubData.quantity}
                    onChange={(event) => handleSubDataNumberChange(event, orderSubData)}
                    size='small'
                    fullWidth
                    type='phone'
                  />
                </Grid>
                <Grid item xs={6} sm={2} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                  {j === order.sub_data.length - 1 && (
                    <IconButton onClick={() => handleAddSubDataClick(order)} color='primary' size='small'>
                      <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                    </IconButton>
                  )}
                  {order.sub_data.length > 1 ? (
                    <IconButton onClick={() => handleRemoveSubDataClick(order, orderSubData.id)} color='error' size='small'>
                      <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  ) : ''}
                  <Badge badgeContent={orderSubData.attachments != undefined ? Object.keys(orderSubData.attachments).length : 0}
                    showZero color="success" overlap="circular">
                    <IconButton onClick={() => handleGalleryClick(order.id, orderSubData.id)} color='success' size='small'>
                      <AddAPhotoIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  </Badge>
                  {getIsAttachmentsUploadPending(orderSubData.attachments) ? (
                    <IconButton color='warning' size='small'>
                      <WarningIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  ) : ''}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )) : ''}
          </Grid>
        </Card>
      </Grid>
    );
  };

  const getPolyesterContent = (order) => {
    return (
      <Grid item xs={12} key={order.id}>
        <Card elevation={12}>
          <Grid key={order.id} container>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
              sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
              <Grid item xs={2.8} sm={1.2} md={1.1}>
                <TextField
                  id="mark_name"
                  name="mark_name"
                  autoComplete ='off'
                  variant='outlined'
                  value={order.mark_name}
                  size='small'
                  fullWidth
                  sx={{ backgroundColor: '#205295', borderRadius: '7px' }}
                  InputProps={{
                    style: { color: 'white' },
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={9.2} sm={4.4} md={4.5}>
                <TextField
                  id="prod_id"
                  label="Product Id"
                  name="prod_id"
                  autoComplete ='off'
                  sx={{ backgroundColor: 'white' }}
                  value={order.prod_id}
                  onChange={(event) => handleProductIdChange(event, order)}
                  size='small'
                  fullWidth
                  onBlur={() => handleProductDetailsSearch(order.id, order.prod_id)}
                />
              </Grid>
              <Grid item xs={7.8} sm={4.4} md={4.5}>
                <TextField
                  id="prod_name"
                  label="Product Name"
                  name="prod_id"
                  autoComplete ='off'
                  sx={{ backgroundColor: 'white' }}
                  value={order.prod_name}
                  size='small'
                  fullWidth
                  disabled={true}
                />
              </Grid>
              <Grid item xs={4.2} sm={2} md={1.9} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                <IconButton onClick={() => handleAddDataClick(order)} color='primary' size='small'>
                  <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                </IconButton>
                <IconButton
                  onClick={() => handleRemoveDataClick(order.id)} color='error' size='small' sx={{ p: 1 }}>
                  <DeleteIcon/>
                </IconButton>
                <IconButton color='primary' onClick={() => handleShowPlanDetails(order)} >
                  {order.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                </IconButton>
              </Grid>
            </Grid>
            {order.is_expanded == true ? order.sub_data.map((orderSubData, j) => (
              <Grid container item xs={12} key={orderSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                <Grid item xs={9} sm={2}>
                  <CustomAutocomplete
                    label={'Variety'}
                    apiInfo={{
                      searchURI: APIData.getVariety,
                      searchField: 'vrty_name',
                      idField: 'vrty_id',
                      searchParams: getVarietyNameSearchParams(order.prod_id)
                    }}
                    defaultValue={{
                      vrty_id: orderSubData.vrty_id,
                      vrty_name: orderSubData.vrty_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleAutoCompleteChange(value, orderSubData, 'vrty_id', 'vrty_name')}
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <TextField
                    id="buy_price"
                    label="Buy Price"
                    name="buy_price"
                    autoComplete ='off'
                    type='number'
                    variant='standard'
                    value={orderSubData.buy_price}
                    onChange={(event) => handleSubDataNumberChange(event, orderSubData)}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <TextField
                    id="description"
                    label="Description"
                    name="description"
                    autoComplete ='off'
                    variant='standard'
                    value={orderSubData.description}
                    onChange={(event) => handleSubDataTextChange(event, orderSubData)}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}sm={1.3}>
                  <TextField
                    id="no_of_sets"
                    label="No Of Sets"
                    name="no_of_sets"
                    autoComplete ='off'
                    variant='standard'
                    type='phone'
                    value={orderSubData.no_of_sets}
                    onChange={(event) =>handleSubDataNumberChange(event, orderSubData)}
                    size='small'
                    fullWidth
                    onBlur={() => computeBuyQuantityForPolyester(orderSubData)}
                  />
                </Grid>
                <Grid item xs={6} sm={1.3}>
                  <TextField
                    id="qty_per_set"
                    label="Quan Per Set"
                    name="qty_per_set"
                    autoComplete ='off'
                    variant='standard'
                    type='phone'
                    value={orderSubData.qty_per_set}
                    onChange={(event) => handleSubDataNumberChange(event, orderSubData)}
                    size='small'
                    fullWidth
                    onBlur={() => computeBuyQuantityForPolyester(orderSubData)}
                  />
                </Grid>
                <Grid item xs={6}sm={1.3}>
                  <TextField
                    id="buy_quantity"
                    label="Buy Qty"
                    name="quantity"
                    autoComplete ='off'
                    variant='standard'
                    type='phone'
                    value={orderSubData.quantity}
                    onChange={(event) => handleSubDataNumberChange(event, orderSubData)}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={2.1} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                  {j === order.sub_data.length - 1 && (
                    <IconButton onClick={() => handleAddSubDataClick(order)} color='primary' size='small'>
                      <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                    </IconButton>
                  )}
                  {order.sub_data.length > 1 ? (
                    <IconButton onClick={() => handleRemoveSubDataClick(order, orderSubData.id)} color='error' size='small'>
                      <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  ) : ''}
                  <Badge badgeContent={orderSubData.attachments != undefined ? Object.keys(orderSubData.attachments).length : 0}
                    showZero color="success" overlap="circular">
                    <IconButton onClick={() => handleGalleryClick(order.id, orderSubData.id)} color='success' size='small'>
                      <AddAPhotoIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  </Badge>
                  {getIsAttachmentsUploadPending(orderSubData.attachments) ? (
                    <IconButton color='warning' size='small'>
                      <WarningIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  ) : ''}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )) : ''}
          </Grid>
        </Card>
      </Grid>
    );
  };

  return (
    <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
      <Grid container direction='row' columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography variant='h5' align='center'>
            Orders Direct Booking
          </Typography>
        </Grid>
        <Grid item xs={7} sm={4}>
          <FormControl fullWidth >
            <InputLabel size="small" id="order-list-select-label"> Order Id</InputLabel>
            <Select
              labelId="order-list-select-label"
              id="order-list-select"
              label="Order Id"
              name='order_id'
              value={orderDetails.order_id}
              onChange={(event) => handleOrderChange(event)}
              size="small"
            >
              {orderList.map((obj) => (
                <MenuItem key={obj.order_id} value={obj.order_id}>
                  {obj.order_id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={5} sm={3}>
          <TextField
            id="Order Date"
            label="Order Date"
            name="order_date"
            autoComplete ='off'
            value={orderDetails.order_date}
            size='small'
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <CustomAutocomplete
            label={'Supplier Name'}
            apiInfo={{
              searchURI: APIData.getSupplier,
              searchField: 'ac_name',
              idField: 'ac_id'
            }}
            defaultValue={{
              ac_id: orderDetails.ac_id,
              ac_name: orderDetails.ac_name
            }}
            onSelectionChange={(event, value) =>
              handleSupplierNameChange(value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {orderDetails.order_data.length > 0 ?
          orderDetails.order_data.map((order, index) => (
            <React.Fragment key={index}>
              {getMaterialContent(order)}
            </React.Fragment>
          )) : ''}
        {validateStringForNull(orderDetails.order_id) ? (
          <Grid item container>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' }, mb: { xs: 1 } }}>
              <Typography>
                {orderDetails.order_data.length > 0 ? 'You can also add' : 'Select Mark'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {markList.map((mark) => (
                <Chip
                  sx={{ m: 1 }}
                  key={mark.item_id}
                  label={mark.mark_name}
                  onClick={() => handleAddMarkClick(mark)}
                />
              ))}
            </Grid>
            {orderDetails.order_data.length > 0 ? (
              <Grid item xs={12} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                <Button
                  type='submit'
                  variant="text"
                  onClick={handleResetClick}
                  sx={{ mr: 2 }}
                >
                  Reset
                </Button>
                <Button
                  type="submit"
                  variant='contained'
                  onClick={handleCreateOrUpdateOrderRequest}
                >
                  {isUpdateRequest == true ? 'Update' : 'Create' }
                </Button>
              </Grid>
            ) : ''}
          </Grid>
        ) : ''}
      </Grid>
      <Dialog
        fullScreen
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }
        }}
        open={isCameraDialogOpen}
        TransitionComponent={Transition}
        onClose={() => {}}
        PaperProps={{
          sx: {
            width: {
              lg: '40%'
            }
          }
        }}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setIsCameraDialogOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Image Capture
            </Typography>
          </Toolbar>
        </AppBar>
        <TSCamera isOpen={isCameraDialogOpen} handleImageCapture={(img) => handleImageCapture(img)}/>
      </Dialog>
      <Dialog
        fullScreen
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }
        }}
        open={isGalleryOpen}
        TransitionComponent={Transition}
        onClose={() => {}}
        PaperProps={{
          sx: {
            width: {
              lg: '40%'
            }
          }
        }}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setIsGalleryOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Gallery
            </Typography>
          </Toolbar>
        </AppBar>
        <TSGallery dataId={cameraParams.dataId} subdataId={cameraParams.subdataId}
          images={cameraParams.images} openCamera={handleAddPhotoClick}
          handleAddPic={handleImageCapture} handleRemovePic={handleImageDelete} handleUploadPic={raiseUploadImage}
          handleDownloadPic={handleDownloadPic}/>
      </Dialog>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </Container>
  );
};

export default OrdersDirectBooking;
