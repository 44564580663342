import React, { useState } from 'react';
import APIData from '../../../utils/APIData';
import { useEffect } from 'react';
import Services from '../../../utils/Services';
import { Utils } from '../../../utils/UtilFunctions';
import CustomSnackbar from '../../../common/components/CustomSnackbar';
import { Backdrop, Box, Container, CircularProgress,
  FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Typography,
  Tab,
  Tabs
  // DialogContent, Button, Card, AppBar, Toolbar, Slide
} from '@mui/material';
import { validateStringForNull } from '../../../utils/FieldValidations';
import { Helmet } from 'react-helmet';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from '@mui/x-data-grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PropTypes from 'prop-types';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="left" ref={ref} {...props} />;
// });

function TabPanel(props) {
  const { node, value, index } = props;
  if (value == index) {
    return (node);
  } else {
    return <React.Fragment/>;
  }
}

TabPanel.propTypes = {
  node: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const Approvals = () => {
  const pageTitle = 'Approvals | Rajmahal Apps';
  const [isLoading, setIsLoading] = useState(false);
  const [approvalsModuleList, setApprovalsModuleList] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [selectedModuleDetails, setSelectedModuleDetails] = useState({
    module_id: '',
    module_name: ''
  });
  // const [isDialogOpen, setIsDialogOpen] = useState(false);
  // const [dialogContent, setDialogContent] = useState(null);
  // const [approvalType, setApprovalType] = useState('');
  const [rows, setRows] = useState({
    yetToBeApproved: [],
    alreadyApproved: []
  });
  const APIToken = {
    GET_APPROVAL_MODULES_LIST: 'AP01',
    GET_APPROVAL_LIST: 'AP02',
    ACCEPT_APPROVAL: 'AP03',
    CANCEL_APPROVAL: 'AP04'
  };

  const requiredParamsConfig = {
    CHIT_COLLECTION: ['acyear', 'req_date:value', 'group_id', 'mem_id'],
    CHIT_CLOSE: ['acyear', 'req_date:value', 'group_id', 'mem_id', 'install'],
    COUPON_VALIDITY: ['acyear', 'req_date:value', 'coup_no'],
    SALES_RETURN: ['acyear', 'rtn_date:value', 'mark_id', 'serial_id'],
    CREDIT_BILL: ['acyear', 'bill'],
    GRN_APPROVAL: ['acyear', 'req_date:value', 'inwd_no'],
    BILL_BALANCE: ['acyear', 'req_date:value', 'vip_id']
  };
  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`
    };
  }

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };

  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );
  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    raiseGetApprovalsListRequest();
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getApprovalModulesList && apiToken == APIToken.GET_APPROVAL_MODULES_LIST ) {
      setApprovalsModuleList(response.data.modules);
    } else if (apiData == APIData.getApprovalList && apiToken == APIToken.GET_APPROVAL_LIST ) {
      const addIdToRows = (data, approvalListId) => {
        if (data == undefined) {
          return [];
        } else {
          return data.map((row, index) => ({ id: `${approvalListId}_${index}`, ...row }));
        }
      };

      const yetToBeApprovedRows = addIdToRows(response.data.yet_to_be_approved, 'yetToBeApproved');
      const alreadyApprovedRows = addIdToRows(response.data.already_approved, 'alreadyApproved');

      setRows({
        yetToBeApproved: yetToBeApprovedRows,
        alreadyApproved: alreadyApprovedRows
      });
    } else if ((apiData == APIData.acceptApproval && apiToken == APIToken.ACCEPT_APPROVAL) ||
       (apiData == APIData.cancelApproval && apiToken == APIToken.CANCEL_APPROVAL) ) {
      showSnackBar('success', 'Approval Details Updated successfully');
      if (callbackValues.moduleId != undefined) {
        raiseGetSelectedApprovalList(callbackValues.moduleId);
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getApprovalModulesList && apiToken == APIToken.GET_APPROVAL_MODULES_LIST ) {
      defaultMsg = 'Failed to get Approvals Modules List';
    } else if (apiData == APIData.getApprovalList && apiToken == APIToken.GET_APPROVAL_LIST ) {
      defaultMsg = 'Failed to get Approval List';
      setRows({
        yetToBeApproved: [],
        alreadyApproved: []
      });
    } else if ((apiData == APIData.acceptApproval && apiToken == APIToken.ACCEPT_APPROVAL) ||
    (apiData == APIData.cancelApproval && apiToken == APIToken.CANCEL_APPROVAL) ) {
      defaultMsg = 'Failed to update Approval Details';
      if (callbackValues.moduleId != undefined) {
        raiseGetSelectedApprovalList(callbackValues.moduleId);
      }
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetApprovalsListRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getApprovalModulesList },
      APIToken.GET_APPROVAL_MODULES_LIST, processSuccess, processError);
  };

  const raiseGetSelectedApprovalList = (moduleId) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getApprovalList, uriValues: [moduleId] },
      APIToken.GET_APPROVAL_LIST, processSuccess, processError);
  };

  const raiseAcceptApprovalRequest = (params) => {
    Services.sendBackendRequest({ apiData: APIData.acceptApproval, uriValues: [selectedModuleDetails.module_id], params: params },
      APIToken.ACCEPT_APPROVAL, processSuccess, processError, { moduleId: selectedModuleDetails.module_id });
  };

  const raiseCancelApprovalRequest = (params) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.cancelApproval, uriValues: [selectedModuleDetails.module_id], params: params },
      APIToken.CANCEL_APPROVAL, processSuccess, processError, { moduleId: selectedModuleDetails.module_id });
  };

  const handleModuleClick = (event) => {
    const selectedModule = approvalsModuleList.find((module) => module.module_id === event.target.value);
    setSelectedModuleDetails(selectedModule);
    if (validateStringForNull(selectedModule.module_id)) {
      raiseGetSelectedApprovalList(selectedModule.module_id);
    }
  };

  // const handleViewClick = (data, apprType) => {
  //   setApprovalType(apprType);
  //   setDialogContent(data);
  //   setIsDialogOpen(true);
  // };

  // const handleDialogClose = () => {
  //   setIsDialogOpen(false);
  //   setDialogContent(null);
  // };

  const validateParams = (params) => {
    const finalParams = {};
    const moduleId = selectedModuleDetails.module_id;
    const requiredParams = requiredParamsConfig[moduleId];

    if (!requiredParams) {
      throw new Error('Invalid Module');
    }

    requiredParams.forEach((key) => {
      const reqParams = key.split(':');
      let value = params[reqParams[0]];
      for ( let i = 1; i< reqParams.length; i++) {
        value = value[reqParams[i]];
      }
      if (!validateStringForNull(value)) {
        throw new Error(`Provide Valid ${reqParams[0].split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}`);
      }
      finalParams[reqParams[0]] = value;
    });
    return finalParams;
  };

  const columnsConfig = {
    CHIT_COLLECTION: [
      { field: 'mem_name', headerName: 'Mem Name', hideable: false, minWidth: 130 },
      { field: 'install', headerName: 'Install', hideable: false, minWidth: 130 },
      { field: 'emi_amt', headerName: 'EMI', hideable: false, minWidth: 120,
        valueGetter: (params) => params.row.emi_amt ? params.row.emi_amt.display : ''
      }
    ],
    CHIT_CLOSE: [
      { field: 'mem_name', headerName: 'Mem Name', hideable: false, minWidth: 160 },
      { field: 'install', headerName: 'Install', hideable: false, minWidth: 100 },
      { field: 'emi_amt', headerName: 'EMI', hideable: false, minWidth: 120,
        valueGetter: (params) => params.row.emi_amt ? params.row.emi_amt.display : ''
      },
      { field: 'end_date', headerName: 'End Date', hideable: false, minWidth: 120,
        valueGetter: (params) => params.row.end_date ? params.row.end_date.display : ''
      }
    ],
    COUPON_VALIDITY: [
      { field: 'coup_no', headerName: 'Coupon No', hideable: false, minWidth: 130 },
      { field: 'party_name', headerName: 'Party Name', hideable: false, minWidth: 150 },
      { field: 'coup_amt', headerName: 'Coupon Amount', hideable: false, minWidth: 150,
        valueGetter: (params) => params.row.coup_amt ? params.row.coup_amt.display : ''
      },
      { field: 'end_date', headerName: 'End Date', hideable: false, minWidth: 120,
        valueGetter: (params) => params.row.end_date ? params.row.end_date.display : ''
      }
    ],
    SALES_RETURN: [
      { field: 'bill', headerName: 'Bill', hideable: false, minWidth: 130 },
      { field: 'mark_id', headerName: 'Mark ID', hideable: false, minWidth: 130 },
      { field: 'serial_id', headerName: 'Serial ID', hideable: false, minWidth: 130 },
      { field: 'days', headerName: 'Days', hideable: false, minWidth: 130,
        valueGetter: (params) => params.row.bill_date ? params.row.bill_date.display : ''
      }
    ],
    CREDIT_BILL: [
      { field: 'bill', headerName: 'Bill', hideable: false, minWidth: 130 },
      { field: 'bill_date', headerName: 'Bill Date', hideable: false, minWidth: 120,
        valueGetter: (params) => params.row.bill_date ? params.row.bill_date.display : ''
      },
      { field: 'party_name', headerName: 'Party Name', hideable: false, minWidth: 150 },
      { field: 'amount', headerName: 'Amount', hideable: false, minWidth: 120,
        valueGetter: (params) => params.row.amount ? params.row.amount.display : ''
      },
      { field: 'discper', headerName: 'Discount Percentage', hideable: false, minWidth: 205 }
    ],
    GRN_APPROVAL: [
      { field: 'ac_name', headerName: 'Account Name', hideable: false, minWidth: 170 },
      { field: 'inwd_no', headerName: 'Inward No', hideable: false, minWidth: 120 },
      { field: 'pydc_no', headerName: 'PYDC No', hideable: false, minWidth: 120 },
      { field: 'pydc_date', headerName: 'PYDC Date', hideable: false, minWidth: 120,
        valueGetter: (params) => params.row.pydc_date ? params.row.pydc_date.display : ''
      },
      { field: 'day', headerName: 'Day', hideable: false, minWidth: 120 }
    ],
    BILL_BALANCE: [
      { field: 'vip_name', headerName: 'Vip Name', hideable: false, minWidth: 170 },
      { field: 'limit_amt', headerName: 'Limit Amount', hideable: false, minWidth: 120,
        valueGetter: (params) => params.row.limit_amt ? params.row.limit_amt.display : ''
      },
      { field: 'bal_amt', headerName: 'Bal Amount', hideable: false, minWidth: 120,
        valueGetter: (params) => params.row.bal_amt ? params.row.bal_amt.display : ''
      },
      { field: 'amount', headerName: 'Amount', hideable: false, minWidth: 120,
        valueGetter: (params) => params.row.amount ? params.row.amount.display : ''
      }
    ]
  };

  const handleApprovalAcceptClick = (apprDetails) => {
    setIsLoading(true);
    try {
      const params = validateParams(apprDetails);
      raiseAcceptApprovalRequest(params);
    } catch (err) {
      showSnackBar('error', 'Failed update Approval details');
      setIsLoading(false);
    }
  };

  const handleApprovalCancelClick = (apprDetails) => {
    setIsLoading(true);
    try {
      const params = validateParams(apprDetails);
      raiseCancelApprovalRequest(params);
    } catch (err) {
      showSnackBar('error', 'Failed update Approval details');
      setIsLoading(false);
    }
  };

  const renderDataGrid = (dataKey, isYetToBeApproved) => {
    if (rows[dataKey] != undefined) {
      const moduleId = selectedModuleDetails.module_id;
      const baseColumns = columnsConfig[moduleId] || [];
      const actionColumns = [];
      if (baseColumns.length > 0) {
        actionColumns.push(
          {
            headerName: 'Actions',
            minWidth: 120,
            renderCell: (params) => (
              <React.Fragment>
                {/* {Utils.isMobile() ? (
                  <IconButton onClick={() => handleViewClick(params.row, title)}>
                    <VisibilityIcon color='primary' />
                  </IconButton>
                ) : ''} */}
                {isYetToBeApproved ? (
                  <IconButton onClick={() => handleApprovalAcceptClick(params.row)}>
                    <CheckCircleIcon color='success'/>
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleApprovalCancelClick(params.row)}>
                    <CancelIcon color='error' />
                  </IconButton>
                )}
              </React.Fragment>
            )
          }
        );
      }
      const columns = [...actionColumns, ...baseColumns];
      return (
        <Paper sx={{ marginBottom: 2 }}>
          <div style={{ minHeight: 400, maxHeight: '40vh', width: '100%' }}>
            {rows[dataKey].length != 0 ? (
              <DataGrid
                rows={rows[dataKey]}
                columns={columns}
                disableColumnSelector
                sx={{ minHeight: 'inherit', maxHeight: 'inherit' }}
                hideable={false}
                getRowId={(row) => row.id}
              />
            ) : <Typography variant='h6' textAlign='center' sx={{ pt: 20 }}>
              {isLoading ? 'Loading....' : 'No Data Available'}
            </Typography>
            }
          </div>
        </Paper>
      );
    }
    return null;
  };

  // const getApprovalDetailsDialogContent = () => {
  //   const moduleFields = columnsConfig[selectedModuleDetails.module_id] || [];

  //   return (
  //     <Dialog fullWidth fullScreen open={isDialogOpen}
  //       sx={{
  //         '& .MuiDialog-container': {
  //           justifyContent: 'flex-end',
  //           alignItems: 'flex-end'
  //         }
  //       }}
  //       TransitionComponent={Transition}
  //       onClose={() => {}}
  //     >
  //       <AppBar position='sticky'>
  //         <Toolbar>
  //           <IconButton
  //             edge="start"
  //             color="inherit"
  //             onClick={() => handleDialogClose()}
  //             aria-label="close"
  //           >
  //             <CloseIcon />
  //           </IconButton>
  //           <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
  //             {selectedModuleDetails.module_name} Approval Details
  //           </Typography>
  //         </Toolbar>
  //       </AppBar>
  //       <DialogContent>
  //         {dialogContent && (
  //           <Grid container rowSpacing={2} sx={{ mt: 1 }}>
  //             <Grid item xs={12}>
  //               <Card elevation={12} sx={{ borderRadius: '15px' }}>
  //                 {moduleFields.map((fieldConfig) => (
  //                   <Grid item container xs={6} rowSpacing={0.5} sx={{ my: 3, ml: 2 }} key={fieldConfig.field}>
  //                     <Grid item xs={12} key={fieldConfig.field}>
  //                       <Typography variant='h5'>
  //                         {fieldConfig.headerName}
  //                       </Typography>
  //                     </Grid>
  //                     <Grid item xs={12}>
  //                       <Typography>
  //                         {!validateStringForNull(dialogContent[fieldConfig.field]) ? '--' :
  //                           (dialogContent[fieldConfig.field]?.display || dialogContent[fieldConfig.field])}
  //                       </Typography>
  //                     </Grid>
  //                   </Grid>
  //                 ))}
  //               </Card>
  //             </Grid>
  //             <Grid item xs={12} display="flex" justifyContent="center" sx={{ mt: 2 }}>
  //               {approvalType === 'Yet To Be Approved' ? (
  //                 <Button
  //                   color="success"
  //                   variant="contained"
  //                   sx={{ borderRadius: '20px' }}
  //                   onClick={() => handleApprovalAcceptClick(dialogContent)}
  //                 >
  //                   Approve
  //                 </Button>
  //               ) : (
  //                 <Button
  //                   color="error"
  //                   variant="contained"
  //                   sx={{ borderRadius: '20px' }}
  //                   onClick={() => handleApprovalCancelClick(dialogContent)}
  //                 >
  //                   Reject
  //                 </Button>
  //               )}
  //             </Grid>
  //           </Grid>
  //         )}
  //       </DialogContent>
  //     </Dialog>
  //   );
  // };

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Box sx={{
        backgroundColor: 'background.default',
        minHeight: '100vh',
        pb: 10,
        pt: 2,
        justifyContent: 'center',
        alignItems: 'flex-start',
        display: 'flex'
      }}>
        <Container>
          <Grid container>
            <Grid item xs={ Utils.isMobile() ? 12 : 4}>
              <FormControl fullWidth size='small'>
                <InputLabel id="module-select-label">Module</InputLabel>
                <Select
                  labelId="module-select-label"
                  value={selectedModuleDetails ? selectedModuleDetails.module_id : ''}
                  onChange={handleModuleClick}
                  label="Module"
                >
                  {approvalsModuleList.map((module) => (
                    <MenuItem key={module.module_id} value={module.module_id}>
                      {module.module_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {/* {getApprovalDetailsDialogContent()} */}
          {validateStringForNull(selectedModuleDetails.module_id) ? (
            <Box
              sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                pt: 1
              }}>
              <Tabs value={currentTab}
                sx={{ backgroundColor: 'white', width: '100%', border: '1px', mb: 3 }}
                onChange={(e, newValue) => setCurrentTab(newValue)}
                scrollButtons
                variant="fullWidth"
                allowScrollButtonsMobile
                indicatorColor="primary">
                <Tab key={0}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      Yet To Be Approved
                    </Box>
                  } {...a11yProps(0)} value={0}/>
                <Tab key={1}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      Already Approved
                    </Box>
                  } {...a11yProps(1)} value={1}/>
              </Tabs>
              <TabPanel key={0} value={currentTab} index={0} node={renderDataGrid('yetToBeApproved', true)}/>
              <TabPanel key={1} value={currentTab} index={1} node={renderDataGrid('alreadyApproved', false)}/>
            </Box>
          ) : ''}
        </Container>
      </Box>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default Approvals;
